import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../DashboarNavbar/DashboardNavbar'
import DriverDoc from './DriverDoc'

function DriverLayout() {
  return (
    <div className='dashboard pl-2 pr-2'>

        <Sidebar/>

        <main className="main-content">
            <div className='main-content'>
            <Navbar/>
            </div>
            <DriverDoc/>
        </main>

    </div>
  )
}

export default DriverLayout