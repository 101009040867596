import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../DashboarNavbar/DashboardNavbar'
import Reports from './Reports'

function ReportsLayout() {
  return (
    <div className='dashboard pl-2 pr-2'>
        
    <Sidebar/>

        <main className='main-content'>
            <div className='main-content'>
            <Navbar/>
            </div>
            <Reports/>
        </main>

    </div>
  )
}

export default ReportsLayout