import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../DashboarNavbar/DashboardNavbar'
import LogCarousel from './Logs'

function LogsLayout() {
  return (
    <div className='dashboard pl-2 pr-2'>

        <Sidebar/>

        <main className="main-content">

            <div className='main-content'>
            <Navbar/>
            </div>

            <LogCarousel/>

        </main>

    </div>
  )
}

export default LogsLayout