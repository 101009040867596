import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../DashboarNavbar/DashboardNavbar';
import passwordHideIcon from './../../Assets/signin/Component 39.png'
import passwordShowIcon from './../../Assets/signin/Component 38.png'
import { UserContext } from '../../../App';
import { adminCompanies, AdminData } from '../../Registration/AdminCompanies/AdminCompanies';
import { Link, useNavigate, useParams } from 'react-router-dom';



function EditDriver() {
    
    const companyId = localStorage.getItem('companyId');
   
    const [checkPassword, setCheckPassword] = useState(true);
    const [showPassword, setShowPassword] = useState(true);
    const [driverInfo, setDriverInfo] = useState({
        first_name: "",
        last_name: "",
        email: "",
        credential: "",            
        password: "",
        notes:"",
        company:companyId,
        licence_state: "",
        phone: "",
        licence_number: "",      
    });

    const navigate = useNavigate()
    const params = useParams();


    useEffect(() =>{
      
        const fectData = async () =>{
            try{
                const response = await axios.get(`https://superservice.uz/api/v1/accounts/drivers/${params.id}/`);
                setDriverInfo(response.data);
                
            }catch(e){
                console.log("error message",e);
                
            }
        }
        
        fectData()
        
        
        
    },[params.id])
    
    console.log(driverInfo);

    const handleEditPassword = async (e) => {
        e.preventDefault();
     


            setCheckPassword(true)
            try {
                const response = await axios.put(`https://superservice.uz/api/v1/accounts/drivers/${params.id}/update/`, driverInfo);
                console.log("Data saved:", response);
                navigate("/driver");

               
            } catch (error) {
                console.error("Error saving data:", error);
                alert("An error occurred while saving the data.")
            }

       

       
    };

    const handleInputChangePassword = (e) => {
        const { name, value } = e.target;
        setDriverInfo((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));

      
        console.log(driverInfo);
        
      
        
    };


    

    return (
        <div className='dashboard pl-2 pr-2'>

            <Sidebar />


            <main className="main-content">
                <Navbar />
                <form onSubmit={handleEditPassword} className='p-3'>

                    <div className="flex items-center justify-between">
                        <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>Edit driver</h2>
                        <div className="flex items-center gap-3">
                            <Link to='/driver' className="edit-button" >
                                cansel
                            </Link>
                            <button className="edit-button" type="submit">
                               Save
                            </button>
                        </div>
                    </div>

                    <div className="border-b-2 pb-2 mb-[30px] ">

                        <span className="tab border-b-2 pb-2 font-semibold" style={{ borderColor: '#1996F0', color: '#1996F0' }}>Driver informations</span>
                    </div>

      {/* ---------------------------------------------------------- */}

                    <div className='flex items-start justify-between gap-4'>
                        <div className="w-[33.3%]">

                            <div className="form-group">
                                <label className=''>First name</label>
                                <input
                                    type="text"
                                    name="first_name"                                    
                                    onChange={handleInputChangePassword}                                    
                                    placeholder='Enter your first name'
                                    value={driverInfo.first_name}
                                    className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    
                                />
                            </div>

                            <div className="form-group">
                                <label className=''>Last name</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    onChange={handleInputChangePassword}                                    
                                    value={driverInfo.last_name}
                                    placeholder='Enter your first name'
                                    className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    
                                />
                            </div>

                            <div className="form-group">
                                <label className=''>Email address</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={driverInfo.email}
                                    onChange={handleInputChangePassword}                                    
                                    maxLength={100}
                                    placeholder='Enter your email'
                                    className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    
                                />
                            </div>
                                                     

                           
                        </div>


                        <div className="w-[33.3%]">

                        

                            <div className="form-group">
                                <label className=' font-semibold'>Password</label>
                                <div className='w-full flex items-center justify-between bg-gray-100 border-[1px] pr-1 rounded-md'>
                                    <input
                                        type= {showPassword ? "password" : 'text'}
                                        name="password"
                                        style={{border:"none"}}
                                        onChange={handleInputChangePassword}
                                        
                                        maxLength={50}
                                        className='w-full focus:outline-none placeholder:text-gray-400 bg-transparent '
                                        
                                    />
                                    <img src={showPassword ? passwordHideIcon : passwordShowIcon} alt="" width={20} onClick={() => setShowPassword(showPassword != true)}/>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className=''>Credential</label>
                                <input
                                    type="text"
                                    name="credential"
                                    value={driverInfo.credential}
                                    onChange={handleInputChangePassword}
                                    
                                    placeholder='Enter your first name'

                                    className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    
                                />
                            </div> 

                            <div className="form-group">
                            <label className=''>Notes</label>
                                <input
                                    type="text"
                                    name="notes"
                                    value={driverInfo.notes}
                                    onChange={handleInputChangePassword}
                                    
                                    placeholder='Enter your notes'
                                    maxLength={100}
                                    className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    
                                />        
                            </div>

                          

                        </div>
                        
                        <div className='w-[33.3%]'>
                            <div className="form-group">
                            <label className=''>Phone number</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={driverInfo.phone}
                                    onChange={handleInputChangePassword}
                                    
                                    placeholder='Enter your phone number'
                                    maxLength={100}
                                    className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    
                                />        
                            </div>
                            <div className="form-group">
                            <label className=''>Drivers license number</label>
                                <input
                                    type="text"
                                    name="licence_number"
                                    value={driverInfo.licence_number}
                                    onChange={handleInputChangePassword}
                                    
                                    placeholder='Enter your license number'
                                    maxLength={50}
                                    className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    
                                />        
                            </div>

                            <div className='form-group'>
                                <label htmlFor="company">Driver's license issuing state
                                </label>
                                <input
                                    type="text"
                                    name="licence_state"
                                    onChange={handleInputChangePassword}
                                    value={driverInfo.licence_state}
                                    
                                    placeholder='Enter your license state'
                                    maxLength={100}
                                    className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    
                                />       
                            </div>

                        </div>

                    </div>


                </form>
            </main>

        </div>
    )
}

export default EditDriver;