import React, { useContext, useState } from 'react';
import './dashboardnavbar.css';
import axios from 'axios';
import { UserContext } from '../../../App';

const Navbar = () => {

  const [searchDriver, setSearchDriver] = useState('')
  const [timeZone, setTimeZone] = useState('')



  const searchDriverFunction = (value) => {

    setSearchDriver(value);
    axios.post("https://671747adb910c6a6e027483d.mockapi.io/register/user/", {
      driver: searchDriver
    })
  }

  const userTimeZone = (value) => {

    setTimeZone(value);
    axios.post("https://671747adb910c6a6e027483d.mockapi.io/register/user/", {
      time_zone: timeZone
    })

    
  }


  const user = useContext(UserContext)


 
  return (
    <div className={`navbar w-full flex items-center justify-between `} style={{background:user.night ? '#fff' : "#1E2D4C",color:user.night ? '' : "#fff",transition:'all 0.3s ease'}}>
      {/* Driver Search */}

      <div className='flex items-center gap-2 border-[2px] border-gray-300 p-1 rounded-lg'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.58268 17.5C13.9549 17.5 17.4993 13.9555 17.4993 9.58329C17.4993 5.21104 13.9549 1.66663 9.58268 1.66663C5.21043 1.66663 1.66602 5.21104 1.66602 9.58329C1.66602 13.9555 5.21043 17.5 9.58268 17.5Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18.3327 18.3333L16.666 16.6666" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <input
          type="text"
          maxLength={100}
          placeholder='Search driver'
          className={`focus:outline-none bg-transparent ${user.night ? "text-gray-400" : 'text-white'}`}
          onChange={(e) => searchDriverFunction(e.target.value)}
        />

      </div>

      {/* Status buttons */}
      <div className="status-buttons">
        <div className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-normal gap-3" style={{ background: "#E2F0ED", color: "#5CB800" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: '#5CB800' }}>1</span>DR</div>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-normal gap-3" style={{ background: '#FFE2E3', color: "#ED3237" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#ED3237" }}>1</span>OFF</span>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-normal gap-3" style={{ background: '#E8F3FF', color: "#1996F0" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#1996F0" }}>0</span>ON</span>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-normal gap-3" style={{ background: '#F3F3F4', color: "#808080" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#808080" }}>0</span>PC</span>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-normal gap-3" style={{ background: '#FFEECE', color: "#FFA800" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#FFA800" }}>0</span>SB</span>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-normal gap-3" style={{ background: '#DCFAFF', color: "#06B6D4" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#06B6D4" }}>0</span>YM</span>

      </div>

      {/* Time period */}

      <div className='flex items-center gap-3'>
        <button>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_54_7542)">
              <rect width="36" height="36" rx="8" fill="white" />
              <path d="M0.5 16C0.5 12.2146 0.501062 9.4259 0.788435 7.28845C1.07387 5.16538 1.63351 3.75989 2.6967 2.6967C3.75989 1.63351 5.16538 1.07387 7.28845 0.788435C9.4259 0.501062 12.2146 0.5 16 0.5H190C193.785 0.5 196.574 0.501062 198.712 0.788435C200.835 1.07387 202.24 1.63351 203.303 2.6967C204.366 3.75989 204.926 5.16538 205.212 7.28845C205.499 9.4259 205.5 12.2146 205.5 16V20C205.5 23.7854 205.499 26.5741 205.212 28.7116C204.926 30.8346 204.366 32.2401 203.303 33.3033C202.24 34.3665 200.835 34.9261 198.712 35.2116C196.574 35.4989 193.785 35.5 190 35.5H16C12.2146 35.5 9.4259 35.4989 7.28845 35.2116C5.16538 34.9261 3.75989 34.3665 2.6967 33.3033C1.63351 32.2401 1.07387 30.8346 0.788435 28.7116C0.501062 26.5741 0.5 23.7854 0.5 20V16Z" fill="#F8F8FA" stroke="#E6E6E6" />
              <path d="M18.0175 10.425C15.2591 10.425 13.0175 12.6667 13.0175 15.425V17.8333C13.0175 18.3417 12.8008 19.1167 12.5425 19.55L11.5841 21.1417C10.9925 22.125 11.4008 23.2167 12.4841 23.5833C16.0758 24.7833 19.9508 24.7833 23.5425 23.5833C24.5508 23.25 24.9925 22.0583 24.4425 21.1417L23.4841 19.55C23.2341 19.1167 23.0175 18.3417 23.0175 17.8333V15.425C23.0175 12.675 20.7675 10.425 18.0175 10.425Z" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" />
              <path d="M19.5579 10.6667C19.2996 10.5917 19.0329 10.5333 18.7579 10.5C17.9579 10.4 17.1913 10.4583 16.4746 10.6667C16.7163 10.05 17.3163 9.61667 18.0163 9.61667C18.7163 9.61667 19.3163 10.05 19.5579 10.6667Z" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5156 23.8833C20.5156 25.2583 19.3906 26.3833 18.0156 26.3833C17.3323 26.3833 16.699 26.1 16.249 25.65C15.799 25.2 15.5156 24.5666 15.5156 23.8833" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" />
            </g>
            <rect x="0.625" y="0.625" width="34.75" height="34.75" rx="7.375" stroke="#E6E6E6" stroke-width="1.25" />
            <defs>
              <clipPath id="clip0_54_7542">
                <rect width="36" height="36" rx="8" fill="white" />
              </clipPath>
            </defs>
          </svg>

        </button>
        
        <button onClick={() => user.NightMode()}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_54_7545)">
              <rect width="36" height="36" rx="8" fill="white" />
              <path d="M0.5 16C0.5 12.2146 0.501062 9.4259 0.788435 7.28845C1.07387 5.16538 1.63351 3.75989 2.6967 2.6967C3.75989 1.63351 5.16538 1.07387 7.28845 0.788435C9.4259 0.501062 12.2146 0.5 16 0.5H190C193.785 0.5 196.574 0.501062 198.712 0.788435C200.835 1.07387 202.24 1.63351 203.303 2.6967C204.366 3.75989 204.926 5.16538 205.212 7.28845C205.499 9.4259 205.5 12.2146 205.5 16V20C205.5 23.7854 205.499 26.5741 205.212 28.7116C204.926 30.8346 204.366 32.2401 203.303 33.3033C202.24 34.3665 200.835 34.9261 198.712 35.2116C196.574 35.4989 193.785 35.5 190 35.5H16C12.2146 35.5 9.4259 35.4989 7.28845 35.2116C5.16538 34.9261 3.75989 34.3665 2.6967 33.3033C1.63351 32.2401 1.07387 30.8346 0.788435 28.7116C0.501062 26.5741 0.5 23.7854 0.5 20V16Z" fill="#F8F8FA" stroke="#E6E6E6" />
              <path d="M9.6906 18.35C9.9906 22.6417 13.6323 26.1333 17.9906 26.325C21.0656 26.4583 23.8156 25.025 25.4656 22.7667C26.1489 21.8417 25.7823 21.225 24.6406 21.4333C24.0823 21.5333 23.5073 21.575 22.9073 21.55C18.8323 21.3833 15.4989 17.975 15.4823 13.95C15.4739 12.8667 15.6989 11.8417 16.1073 10.9083C16.5573 9.875 16.0156 9.38333 14.9739 9.825C11.6739 11.2167 9.4156 14.5417 9.6906 18.35Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <rect x="0.625" y="0.625" width="34.75" height="34.75" rx="7.375" stroke="#E6E6E6" stroke-width="1.25" />
            <defs>
              <clipPath id="clip0_54_7545">
                <rect width="36" height="36" rx="8" fill="white" />
              </clipPath>
            </defs>
          </svg>

        </button>

        <div className='flex items-center gap-2 border-[2px] border-gray-300 p-1 rounded-lg'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66602 1.66666V4.16666" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.334 1.66666V4.16666" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.91602 7.57501H17.0827" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.5 7.08332V14.1667C17.5 16.6667 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6667 2.5 14.1667V7.08332C2.5 4.58332 3.75 2.91666 6.66667 2.91666H13.3333C16.25 2.91666 17.5 4.58332 17.5 7.08332Z" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.0781 11.4167H13.0856" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.0781 13.9167H13.0856" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.99607 11.4167H10.0036" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.99607 13.9167H10.0036" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.91209 11.4167H6.91957" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.91209 13.9167H6.91957" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <select 
          name="time_zone"
          className='border-none focus:outline-none bg-transparent'
          onChange={(e) => userTimeZone(e.target.value)}
          >
            <option value="" selected>Time zone</option>
            <option value="eastren">Eastren</option>
            <option value="central">Central</option>
            <option value="mountain">Mountain</option>
            <option value="pacific">Pacific</option>
          </select>

        </div>

      </div>

    </div>
  );
};

export default Navbar;
