
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import passwordShowIcon from '../../Assets/signin/Component 38.png'
import passwordHideIcon from '../../Assets/signin/Component 39.png'

const PasswordSet = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('')
    const [errorPassword,setErrorPassword] = useState(true)
    const [showPassword,setShowPassword] = useState(true);
    const [showPassword2,setShowPassword2] = useState(true);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        if(confirmPassword === password){
            try {
                const response = await fetch('https://671747adb910c6a6e027483d.mockapi.io/register/user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({password}),
                });
                
    
                const data = await response.json();
                if (response.ok) {
                    console.log(data);
                   // localStorage.setItem("userPassword", password) //backend bitganda tokenni saqlab olish uchun qildim 
                    navigate("/layout")
    
                } else {
                    alert('Login failed!');
                }
            } catch (error) {
                console.error('Error:', error);
                alert("not corrent password or email")
            }
        }else{
            setErrorPassword(false)
        }
       
    };

    
    
    
    return (
        <header className='signin_header'>
            <div className="row_box">
                <div className="form_container flex items-center justify-center">
                    <form onSubmit={handleLogin} className='w-7/12 min-w-96 p-10'>
                        <div className='mb-8'>
                            <p className='text-base'>Sign up</p>
                            <h3 className='text-5xl font-semibold' style={{ color: "#1996F0" }}>Password set</h3>
                            <p className='text-base text-gray-500 mt-2'>Kindly enter a password.</p>
                        </div>

                        <div className='mb-5'>
                            <label htmlFor="userFullName" className='mb-4 text-base'>Password</label>

                          <div className='flex items-center justify-between bg-white p-1 rounded-xl '>
                          <input
                                type={showPassword ? 'password' : "text"}
                                placeholder=""
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                id='userFullName'
                                className='w-full rounded-xl p-2 text-gray-500'
                                maxLength={60}
                                minLength={4}
                                required
                            />
                           <img src={showPassword ? passwordHideIcon : passwordShowIcon} alt="" onClick={() => setShowPassword(showPassword != true)}/>
                          </div>
                        </div>

                        <div>
                            <label htmlFor="userpassword" className='mb-4 text-base'>Comfirm password</label>
                            <div className='flex items-center justify-between bg-white p-2 rounded-xl mb-4'>
                                <input
                                    type={showPassword2 ? 'password' : 'text'}
                                    placeholder=""
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    id='userEmail'
                                    className={`w-full bg-transparent ${errorPassword ? 'text-gray-500' : 'text-red-600'}`}
                                    maxLength={30}
                                    minLength={4}
                                    required
                                   
                                />
                                <img src={showPassword2 ? passwordHideIcon : passwordShowIcon} alt="" onClick={() => setShowPassword2(showPassword2 != true)}/>
                            </div>
                                <p className={`mb-2 text-red-600 ${errorPassword ? 'hidden' : 'block'}`}>incorrect password</p>
                        </div>

                        <button type="submit" className='w-full text-white text-center p-2 rounded-xl' style={{ background: "#1996F0" }}>Verify</button>

                    </form>
                </div>
                <div className="img_container">

                </div>
            </div>
        </header>
    );
};

export default PasswordSet;
