import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SignUpVerification = () => {
    const [code, setCode] = useState(Array(6).fill(""));
    const [timeLeft, setTimeLeft] = useState(180);
    const navigate = useNavigate()
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === "") {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
            try {
                const response = await fetch('https://671747adb910c6a6e027483d.mockapi.io/register/user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({code}),
                });
    
                const data = await response.json();
                if (response.ok) {
                    console.log(data);
                   // localStorage.setItem("userPassword", password) //backend bitganda tokenni saqlab olish uchun qildim 
                    navigate("/passwordset")
    
                } else {
                    alert('Login failed!');
                }
            } catch (error) {
                console.error('Error:', error);
                alert("not corrent password or email")
            }
      
    };

    const handleResend = () => {
        setCode(Array(6).fill(""));
        setTimeLeft(180); 
        axios.post('https://671747adb910c6a6e027483d.mockapi.io/register/user',{userEmail:localStorage.getItem("userEmail")});
        alert("A new password has been sent to your email")
    };

    return (
        <header className="signin_header">
            <div className="row_box">
                <div className="form_container flex items-center justify-center">
                    <form className='w-7/12 min-w-96 p-10' onSubmit={handleSubmit}>
                        <div>
                            <div >
                                <div className="mb-7">
                                    <h2>Sign Up</h2>
                                    <h3 className="text-4xl font-bold" style={{ color: "#1996F0" }}>Email verification</h3>
                                    <p className="text-base font-normal" style={{ color: "#808080" }}>
                                        Kindly enter the 6 digit code that has been sent to your email address.
                                    </p>
                                </div>

                                <div>
                                    <div className="flex items-center justify-between mb-4">
                                        <p>Enter verification code</p>
                                        <span>
                                            {String(Math.floor(timeLeft / 60)).padStart(2, "0")}:
                                            {String(timeLeft % 60).padStart(2, "0")}
                                        </span>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        {code.map((digit, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                value={digit}
                                                onChange={(e) => handleChange(e, index)}
                                                maxLength={1}
                                                placeholder="."
                                                required
                                                className="text-center font-medium text-base rounded-lg"
                                                style={{ width: "40px", height: "40px",color:"#1996F0"}}
                                            />
                                        ))}
                                    </div>
                                    <div className="mt-7">
                                    <button  type="submit" className='w-full text-white text-center p-2 rounded-xl' style={{ background: "#1996F0" }}>
                                    Check
                                </button>
                                <br />
                                <p onClick={handleResend} className="text-center mt-4 text-gray-500">
                                    Resend verification code
                                </p>
                                    </div>
                                </div>

                     
                            </div>
                        </div>
                    </form>
                </div>
                <div className="img_container">

                </div>
            </div>
        </header>
    );
};

export default SignUpVerification;
