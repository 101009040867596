import React,{useCallback, useState} from 'react'
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api'
import MarkerIcon from '../../Assets/signin/direct-right.png'
import FrameOn from '../../Assets/DashboardMap/FrameOn.png'
import FrameOff from '../../Assets/DashboardMap/FrameOff.png'
import WeightStation from '../../Assets/DashboardMap/Weight station.png'
const containerStyle = {
  width: '100%',
  height: '85vh',
}

const center = {
  lat: 41.311081,
  lng: 69.240562,
}


function DashboardMap({typeId,isLoadedMap,markerLocation , vehicles,drivers,weightstation,activeLink}) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAUS-bGaDXizD-0fPlyMyki5rPPT-rGUD4",
  })

  const [map, setMap] = useState(null);
 

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center)
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  const options = {
    mapTypeId: typeId, // Satellite rejimni yoqish
    zoomControl: true,      // Zoom nazorati
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
    >

      
      <MarkerClusterer
  options={{
    gridSize: 60,
    maxZoom: 15,
    averageCenter: true,
    enableRetinaIcons: true,
  }}
>
  {(clusterer) =>
    activeLink == 'drivers' ? 
    drivers.map((item) => {
      if (item.location && item.location.lat && item.location.lng) {
        return (
          <Marker
            key={item.id}
            position={item.location}
            options={{
              icon: {
                url: MarkerIcon,
                scaledSize: new window.google.maps.Size(40, 40),
              },
            }}
            clusterer={clusterer}
          />
        );
      } else {
        console.warn("Invalid location for driver:", item);
        return null;
      }
    }) : 
    activeLink == "vehicles" ?
    weightstation.map((item) => {
      console.log(item)
      
      if (item.location && item.location.lat && item.location.lng) {
        return (
          <Marker
            key={item.id}
            position={item.location}
            options={{
              icon: {
                url: item.type == "DR" ? FrameOn : FrameOff ,
                scaledSize: new window.google.maps.Size(40, 40),
              },
            }}
            clusterer={clusterer}
          />
        );
      } else {
        console.warn("Invalid location for driver:", item);
        return null;
      }
    }) : activeLink == "weightstation" ?
    vehicles.map((item) => {
     
      
      if (item.location && item.location.lat && item.location.lng) {
        return (
          <Marker
            key={item.id}
            position={item.location}
            options={{
              icon: {
                url: WeightStation ,
                scaledSize: new window.google.maps.Size(40, 40),
              },
            }}
            clusterer={clusterer}
          />
        );
      } else {
        console.warn("Invalid location for driver:", item);
        return null;
      }
    }) : <h1>Loading...</h1>
  }
</MarkerClusterer>
   
      
      
          
    </GoogleMap>
  ) : (
    <></>
    
  )
}

export default React.memo(DashboardMap)