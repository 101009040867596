import axios from 'axios'
import React, { useEffect, useState } from 'react'


function Resources() {
    const [files, setFiles] = useState([])

    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(`https://superservice.uz/api/v1/main/recources-list/`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
                    },
                });
                console.log("select", response);
                setFiles(response.data);

            } catch (err) {
                console.log(err.message || 'Xato yuz berdi!');
            } finally {
                
            }
        };

        fetchData()
    
    }, [accessToken]);

    console.log(files);
    

    return (
        <div className='p-3'>

          <div className='main-content'>
          <div className=' h-[80px] flex items-center justify-between border-b-[1px]'>
                <h1 className='font-semibold text-[30px]' style={{ color: "#1996F0" }}>Resources</h1>
                <div className='flex items-center gap-2 border-[1px] p-1 pl-3 rounded-lg w-[250px]'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.58268 17.5C13.9549 17.5 17.4993 13.9556 17.4993 9.58335C17.4993 5.2111 13.9549 1.66669 9.58268 1.66669C5.21043 1.66669 1.66602 5.2111 1.66602 9.58335C1.66602 13.9556 5.21043 17.5 9.58268 17.5Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.3327 18.3334L16.666 16.6667" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <input type="text" placeholder='Search' className='border-none focus:outline-none placeholder:font-semibold' />

                </div>
            </div>

            <div className='w-full flex gap-3 flex-wrap mt-6'>
                {files.map((file, index) => (
                  
                        
                            <div key={index} className=' w-[32%] flex justify-between flex-col p-3 border-[2px] rounded-lg'>
                                <div className='mb-3'>
                                <h3 className='text-[20px] font-bold'>{file.title}</h3>
                                <p className='text-gray-500'>{file.description}</p>
                                </div>
                                <a href={file.document_url} download>
                                    <button className='flex items-center gap-3 p-1 pl-3 pr-3 rounded-lg' style={{background:"#E8F3FF"}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.76758 9.73334L9.90091 11.8667L12.0342 9.73334" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.90039 3.33331V11.8083" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.6673 10.15C16.6673 13.8334 14.1673 16.8167 10.0007 16.8167C5.83398 16.8167 3.33398 13.8334 3.33398 10.15" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <p style={{color:"#1996F0"}}>Download</p>

                                    </button>
                                </a>
                            </div>
                ))}
            </div>
          </div>

        </div>
    )
}

export default Resources