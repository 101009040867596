import React, { useContext, useEffect, useState } from 'react';
import './dashboardlayout.css';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../DashboarNavbar/DashboardNavbar';
import DashboardMap from '../DashboardMap/DashboardMap';
import CloseIcon from '../../Assets/DashboardMap/close.png'
import RefreshIcon from '../../Assets/DashboardMap/refresher.png'
import FrameGreen from '../../Assets/Sidebar/Frame-green.png'
import FrameGray from '../../Assets/Sidebar/Frame-gray.png'
import axios from 'axios';
import { UserContext } from '../../../App';




const Layout = () => {

  const [linkActive, setLinkActive] = useState(null);
  const [locationCard, setLocationCard] = useState([]);
  const [selectLocation, setSelectLocation] = useState([]);
  const [searchDriverArr, setSearchDriverArr] = useState('');
  const [checkedType, setCheckedType] = useState('')
  const [closeBar, setCloseBar] = useState(true)
  const [typeId, setTypeId] = useState('roadmap')
  const [data, setData] = useState([]);
  // const getCardData = async () => {
  //   await axios.get('https://xertzdigital.net/geolocation.json').then(res => {
  //     setLocationCard(res.data)

  //   })

  //   setIsloaded(true)
  // }


  const accessToken = localStorage.getItem('accessToken');
  const companyId = localStorage.getItem("companyId");
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://superservice.uz/api/v1/main/dashboard/driver_list/${companyId}?last_activity_status=${checkedType}&full_name=${searchDriverArr}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
          },
        });
        console.log(response);

        setData(response.data);

      } catch (err) {
        console.log(err.message || 'Xato yuz berdi!');
      } finally {

      }
    };
    fetchData();

  }, [accessToken]);

  console.log("fetch data", data);


  const Drivers = async (click) => {
    await axios.get('https://xertzdigital.net/geolocation.json').then(res => {
      setLocationCard(res.data)
      setSelectLocation(res.data)
    })
    setLinkActive('drivers');
  }

  const Vehicles = async (active) => {

    await axios.get('https://xertzdigital.net/vehicles.json').then(res => {
      setSelectLocation(res.data)
    })

    setLinkActive(active);
  }
  const WeightSation = async (active) => {
    await axios.get('https://xertzdigital.net/weightstation.json').then(res => {

      setSelectLocation(res.data)
    })
    setLinkActive(active);
  }




  const searchDriver = (e) => {

    setSearchDriverArr(e.target.value);
    console.log(searchDriverArr);


  }

  const selectedType = (e) => {
    setCheckedType((prevFilters) =>
      prevFilters.includes(e.target.value)
        ? prevFilters.filter((item) => item !== e.target.value) // Agar tanlangan bo‘lsa, o‘chirish
        : [...prevFilters, e.target.value]);
    console.log("checked", checkedType);

  }

  const changeMap = () => {
    setTypeId(typeId == 'roadmap' ? "satellite" : "roadmap")
  }

  const user = useContext(UserContext)

  return (
    <div className="dashboard pl-2 pr-2" style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }} onLoad={Drivers}>
      {/* Sidebar */}

      <Sidebar />

      {/* Main Content */}
      <main className="main-content">
        <div className='main-content'>
          <Navbar />
        </div>
        {/* Map Section */}

        <div className='main-content'>
          <div className='flex justify-between'>

            <DashboardMap typeId={typeId} markerLocation={locationCard} vehicles={selectLocation} drivers={selectLocation} weightstation={selectLocation} activeLink={linkActive} />

            <div className={` ${closeBar ? 'w-1/3' : 'w-0'} bg-white p-3`} style={{ height: "90vh", maxHeight: "85vh", overflowY: 'scroll', background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>


              <div className='flex items-center justify-between'>
                <img
                  src={CloseIcon}
                  alt="closeicon"
                  onClick={() => setCloseBar(closeBar != true)}
                  style={
                    {
                      position: closeBar ? 'relative' : 'absolute',
                      right: closeBar ? '0px' : "100px",

                    }
                  }
                />
                <div className='flex items-center gap-2'>
                  <button className='p-1 pl-4 pr-4 rounded-lg font-semibold' style={{ background: "#E8F3FF", color: "#1996F0" }} onClick={() => changeMap()} >{typeId == "roadmap" ? "Satellite" : "roadMap"}</button>
                  <img src={RefreshIcon} alt="" />
                </div>
              </div>

              <ul className='flex items-center justify-between mt-3 text-gray-400 cursor-pointer '>
                <li onClick={() => { Drivers('drivers') }} className={`${linkActive === "drivers" ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}>Drivers</li>
                <li onClick={() => Vehicles('vehicles')} className={`${linkActive === "vehicles" ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}>Vehicles</li>
                <li onClick={() => WeightSation('weightstation')} className={`${linkActive === "weightstation" ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}>Weight Sation</li>
              </ul>
              <hr className='mt-4' style={{ backgroundColor: "red" }} />

              {/* <div>

    <div className='w-full p-2 flex items-center justify-between rounded-lg gap-2' style={{ border: "2px solid #E6E6E6" }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.58341 17.5001C13.9557 17.5001 17.5001 13.9557 17.5001 9.58348C17.5001 5.21122 13.9557 1.66681 9.58341 1.66681C5.21116 1.66681 1.66675 5.21122 1.66675 9.58348C1.66675 13.9557 5.21116 17.5001 9.58341 17.5001Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18.3334 18.3335L16.6667 16.6668" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <input
        type="text"
        maxLength={100}
        placeholder='Search driver'
        className='w-full focus:outline-none bg-transparent'
        style={{ color: "#808080" }}
        onChange={searchDriver}
      />
    </div>

    <form>
      <div className='w-full flex items-center justify-between mt-5'>


        <div className='p-1 pl-2 pr-2 flex gap-2 rounded-md' style={{ background: "#F8F8FA" }}>
          <input type="checkbox" onClick={selectedType} value='all' />
          <span className='text-sm font-semibold text-gray-500'>All</span>
        </div>

        <div className='p-1 pl-2 pr-2 flex gap-2 rounded-md' style={{ background: "#E2F0ED" }}>
          <input type="checkbox" onChange={selectedType} value='dr' />
          <span className='text-sm font-semibold text-green-600'>DR</span>
        </div>

        <div className='p-1 pl-2 pr-2 flex gap-2 rounded-md' style={{ background: "#E8F3FF" }}>
          <input type="checkbox" onClick={selectedType} value='on' />
          <span className='text-sm font-semibold text-blue-600'>ON</span>
        </div>

        <div className='p-1 pl-2 pr-2 flex gap-2 rounded-md' style={{ background: "#FFEECE" }}>
          <input type="checkbox" onClick={selectedType} value='sb' />
          <span className='text-sm font-semibold text-yellow-600'>SB</span>
        </div>

        <div className='p-1 pl-2 pr-2 flex gap-2 rounded-md' style={{ background: "#FFE2E3" }}>
          <input type="checkbox" onClick={selectedType} value='off' />
          <span className='text-sm font-semibold text-red-600'>OFF</span>
        </div>


      </div>
    </form>

    {
      

        data.map((item) => {
          return (
            <div key={item.id}
              className={`flex items-start my-7 pb-2 gap-3 border-b-2`}>
              <div>
                {item.last_activity.activity == 'off' ? (<img src={FrameGray} />) : (<img src={FrameGreen} />)}
              </div>
              <div className='w-full'>
                <div className=' w-full flex items-center justify-between'>
                  <p className='text-base font-semibold'>{item.first_name} {item.last_name}</p>
                  <div className='flex items-center justify-between gap-2'>
                    <button>
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.00012207" width="19" height="19" rx="3.8" fill="#E8F3FF" />
                        <path d="M10.5 9.50015L14.6 5.40015" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.0001 7.40012V5.00012H12.6001" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.5 5.00012H8.5C6 5.00012 5 6.00012 5 8.50012V11.5001C5 14.0001 6 15.0001 8.5 15.0001H11.5C14 15.0001 15 14.0001 15 11.5001V10.5001" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    <button>
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.00012207" width="19" height="19" rx="3.8" fill="#E8F3FF" />
                        <path d="M12.5 10.7002V12.2002C12.5 14.2002 11.7 15.0002 9.7 15.0002H7.8C5.8 15.0002 5 14.2002 5 12.2002V10.3002C5 8.30024 5.8 7.50024 7.8 7.50024H9.3" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.4998 10.7002H10.8998C9.6998 10.7002 9.2998 10.3002 9.2998 9.10024V7.50024L12.4998 10.7002Z" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.80005 5.00024H11.8" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.5 6.50024C7.5 5.67024 8.17 5.00024 9 5.00024H10.31" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.0002 8.00024V11.0952C15.0002 11.8702 14.3702 12.5002 13.5952 12.5002" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15 8.00024H13.5C12.375 8.00024 12 7.62524 12 6.50024V5.00024L15 8.00024Z" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                    </button>

                    <button className={` pr-2 pl-2 rounded-lg text-white ${item.last_activity.activity == 'dr' ? 'bg-green-500' : item.last_activity.activity == 'sb' ? 'bg-yellow-400' : item.last_activity.activity == 'on' ? 'bg-blue-300' : 'bg-red-600'}`}>
                      {item.last_activity.activity}
                    </button>

                  </div>
                </div>
                <div className='text-lg text-gray-500'>ID:<small className='text-[16px] font-medium'> {item.id}</small> <span>|</span> <small className={`${item.last_activity.activity === 'off' ? 'text-black' : 'text-green-400'}`}>{item.last_activity.activity}</small></div>
                <div className='text-lg  flex items-center gap'><svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 12.1876C10.0888 12.1876 12.1875 10.089 12.1875 7.50012C12.1875 4.91129 10.0888 2.81262 7.5 2.81262C4.91117 2.81262 2.8125 4.91129 2.8125 7.50012C2.8125 10.089 4.91117 12.1876 7.5 12.1876Z" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.5 9.37512C8.53553 9.37512 9.375 8.53566 9.375 7.50012C9.375 6.46459 8.53553 5.62512 7.5 5.62512C6.46447 5.62512 5.625 6.46459 5.625 7.50012C5.625 8.53566 6.46447 9.37512 7.5 9.37512Z" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.5 2.50012V1.25012" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M2.5 7.50012H1.25" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.5 12.5001V13.7501" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12.5 7.50012H13.75" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                </svg>:<small className='text-sm font-normal'>{item.last_activity.location}</small>
                </div>
                <div className=' flex items-center gap'>
                  <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.1875 13.7501V3.12512C2.1875 1.87512 3.025 1.25012 4.0625 1.25012H9.0625C10.1 1.25012 10.9375 1.87512 10.9375 3.12512V13.7501H2.1875Z" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.25 13.7501H11.875" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5.24375 6.25014H7.8875C8.5375 6.25014 9.06875 5.93764 9.06875 5.06889V4.30015C9.06875 3.4314 8.5375 3.1189 7.8875 3.1189H5.24375C4.59375 3.1189 4.0625 3.4314 4.0625 4.30015V5.06889C4.0625 5.93764 4.59375 6.25014 5.24375 6.25014Z" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4.0625 8.12512H5.9375" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.9375 10.0064L13.75 10.0001V6.25012L12.5 5.62512" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Fuel: <small className='text-sm font-normal'>{item.last_activity.fuel_percent}</small>
                </div>
                  <div className='text-sm flex'>Last connection: <span className=''>{item.last_activity.start_time}</span></div>
              </div>
            </div>
          )
        })

        

      

    }

  </div> */}

            </div>

          </div>
        </div>

      </main >
    </div >
  );
};

export default Layout;
