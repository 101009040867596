import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../App';
import Pagination from '@mui/material/Pagination';


function TrackingDoc() {
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);


    let companyId = localStorage.getItem("companyId");


    const fetchData = async (page) => {

        try {
            const response = await axios.get(`https://superservice.uz/api/v1/main/dashboard/tracking/${companyId}/?page=${page}`);
            setLogs(response.data.results);
            setTotalPages(response.data.total_pages);
            console.log(response);
            

        } catch (error) {
            console.error('Xato:', error);
        }
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const handleChange = (e, p) => {

        setCurrentPage(p);
    }

console.log(logs);



    const user = useContext(UserContext)
    return (
        <div className='p-3' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>

           <div className='main-content'>
           <div className='w-full h-full' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>


<div className='flex items-center justify-between border-b-[1px] border-gray-300 mb-3 pb-[5px]'>
    <h1 className='text-[30px] font-bold' style={{ color: '#1996F0' }}>Traking</h1>
    <div className='flex items-center gap-5'>

        <div className='flex items-center gap-2 border-[1px] border-gray-400 rounded-lg p-1'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.58366 17.5C13.9559 17.5 17.5003 13.9556 17.5003 9.58335C17.5003 5.2111 13.9559 1.66669 9.58366 1.66669C5.2114 1.66669 1.66699 5.2111 1.66699 9.58335C1.66699 13.9556 5.2114 17.5 9.58366 17.5Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.3337 18.3334L16.667 16.6667" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <input type="text" placeholder='Search' maxLength={100} className='focus:outline-none placeholder:text-gray-500 font-semibold' />
        </div>

        <div className='border-[1px] border-gray-400 p-1 rounded-lg text-gray-500 font-semibold'>
            <input type="date" />
        </div>

        <div className='flex items-center'>
            <button
                className=' pr-1 pl-1 text-2xl  flex items-center justify-between gap-2 border-none'
                style={{ background: '#E8F3FF', color: '#1996F0' }}
            >
                {'<'}
            </button>
            <button
                className=' pr-1 pl-1 text-2xl  flex items-center justify-between gap-2 border-none'
                style={{ background: '#E8F3FF', color: '#1996F0' }}
            >
                {'>'}
            </button>
        </div>

        <button>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                <path d="M20.8901 11.08C20.0201 10.82 19.0601 10.65 18.0001 10.65C13.2101 10.65 9.33008 14.53 9.33008 19.32C9.33008 24.12 13.2101 28 18.0001 28C22.7901 28 26.6701 24.12 26.6701 19.33C26.6701 17.55 26.1301 15.89 25.2101 14.51" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22.1302 11.32L19.2402 8" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22.1298 11.3199L18.7598 13.7799" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </button>

    </div>
</div>

<div className=' h-[73vh] w-full border-2 rounded-lg p-4 flex items-center justify-between flex-col'>


    <table style={{width: '100%' }}>
        <thead>
            <tr className='font-bold border-b-2 pb-3' style={{ color: "#808080" }}>
                <th>STATUS</th>
                <th>DRIVER</th>
                <th>VEHICLE ID</th>
                <th>LAST KNOW LOCATION</th>
            </tr>
        </thead>
        <tbody>

                    {logs.map((log, index) => (

                        <tr key={index} className=' border-b-2 text-center h-[50px]'>

                            <td>
                                <button
                                    className=' pl-3 pr-3 rounded-lg text-green-600 font-bold bg-green-100'
                                    style={{

                                        backgroundColor: log.status == 'DR' ? '#E2F0ED' : log.status == 'SB' ? '#FFEECE' : '#FFE2E3',
                                        color: log.status == 'DR' ? '#5CB800' : log.status == 'SB' ? '#FFA800' : '#ED3237'
                                    }}>{log.last_activity != null ? log.last_activity.activity : ''}</button>
                            </td>

                            <td> <Link to={`/tracking/${log.id}`}>{log.first_name} {log.last_name}</Link></td>

                            <td>{log.last_activity != null ? log.last_activity.vehicle_number : "not active"}</td>

                            <td className='flex justify-center items-center h-[50px] gap-4'>

                                {log.last_activity != null ? log.last_activity.location : "not active"}


                                <div className='flex items-center gap-2'>
                                    <button>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                            <path d="M10.5 9.50002L14.6 5.40002" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M14.9996 7.4V5H12.5996" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.5 5H8.5C6 5 5 6 5 8.5V11.5C5 14 6 15 8.5 15H11.5C14 15 15 14 15 11.5V10.5" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </button>
                                    <button>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                            <path d="M12.5 11.2001V12.7001C12.5 14.7001 11.7 15.5001 9.7 15.5001H7.8C5.8 15.5001 5 14.7001 5 12.7001V10.8001C5 8.80006 5.8 8.00006 7.8 8.00006H9.3" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.4998 11.2001H10.8998C9.6998 11.2001 9.2998 10.8001 9.2998 9.60006V8.00006L12.4998 11.2001Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.7998 5.50006H11.7998" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.5 7.00006C7.5 6.17006 8.17 5.50006 9 5.50006H10.31" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M14.9997 8.50006V11.5951C14.9997 12.3701 14.3697 13.0001 13.5947 13.0001" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M15 8.50006H13.5C12.375 8.50006 12 8.12506 12 7.00006V5.50006L15 8.50006Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </button>

                                </div>


                            </td>


                          
                        </tr>

                    ))}

        </tbody>
    </table>

    <div className=' flex items-center justify-center'>
            <Pagination count={totalPages} shape="rounded" color='primary' onChange={handleChange} />
    </div>

</div>
</div>
           </div>

        </div>
    )
}

export default TrackingDoc