import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link, useNavigate } from 'react-router-dom';


function ReportHistory() {
  const [logs, setLogs] = useState([]);
  const itemsPerPage = 5; // har bir sahifada ko'rsatish uchun elementlar soni
  const [page, setPage] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://xertzdigital.net/report_history.json');
        setLogs(response.data);
      } catch (error) {
        console.error("Ma'lumotlarni yuklashda xatolik:", error);
      }
    };
    fetchData();
  }, []);

  const totalPages = Math.ceil(logs.length / itemsPerPage);

  const getPageData = () => {
    const start = page * itemsPerPage;
    return logs.slice(start, start + itemsPerPage);
  };

const deleteItem = (id) =>{
   axios.delete(`https://671747adb910c6a6e027483d.mockapi.io/register/user/${id}`)
   .then(res => {
    if(res.status == 200){
        alert("Item Deleted")
    }
   })
}


  return (
    <div className='p-3'>
      <Carousel showThumbs={false} showStatus={false}>
        {Array.from({ length: totalPages }, (_, i) => (
          <div key={i} className='w-full bg-white h-full'>



            {/* ------------------------------------------------------------------------------------------------------------------ */}

            <div className='w-full border-2 rounded-lg p-4'>


              <table style={{ height: '60vh', width: '100%' }}>
                <thead>
                  <tr className='font-normal text-sm border-b-2 pb-3' style={{ color: "#808080" }}>

                    <th>DRIVER</th>
                    <th>REPORT TYPE</th>
                    <th>INTERVAL DATE</th>
                    <th>CREATED TIME</th>
                    <th>DOWNLOAD</th>
                    <th>DELETE</th>

                  </tr>
                </thead>
                <tbody>

                  {getPageData().map((log, index) => (

                    <tr key={index} className='border-b-[2px]'>
                      <td>{log.driver}</td>
                      <td>{log.report_type}</td>
                      <td>{log.interval_date}</td>
                      <td>{log.created_time}</td>
                      <a href={log.download} download className='h-full flex items-center justify-center'>
                        <td>
                          <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" width="35" height="35" rx="4" fill="#E8F3FF" />
                            <path d="M15.7656 17.7334L17.899 19.8667L20.0323 17.7334" stroke="#1996F0" stroke-width="1.45833" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.9004 11.3334V19.8084" stroke="#1996F0" stroke-width="1.45833" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M24.6673 18.15C24.6673 21.8334 22.1673 24.8167 18.0007 24.8167C13.834 24.8167 11.334 21.8334 11.334 18.15" stroke="#1996F0" stroke-width="1.45833" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </td>
                      </a>


                      <td>
                        <button onClick={() => deleteItem(log.id)}>
                          <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" width="35" height="35" rx="4" fill="#FFE2E3" />
                            <path d="M26 12.9833C23.225 12.7083 20.4333 12.5667 17.65 12.5667C16 12.5667 14.35 12.65 12.7 12.8167L11 12.9833" stroke="#ED3237" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.584 12.1416L15.7673 11.05C15.9007 10.2583 16.0007 9.66663 17.409 9.66663H19.5923C21.0007 9.66663 21.109 10.2916 21.234 11.0583L21.4173 12.1416" stroke="#ED3237" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M24.2077 15.6167L23.666 24.0084C23.5743 25.3167 23.4993 26.3334 21.1743 26.3334H15.8243C13.4993 26.3334 13.4243 25.3167 13.3327 24.0084L12.791 15.6167" stroke="#ED3237" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.1074 21.75H19.8824" stroke="#ED3237" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.416 18.4166H20.5827" stroke="#ED3237" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </button>
                      </td>

                    </tr>

                  ))}

                </tbody>
              </table>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default ReportHistory