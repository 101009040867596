import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../DashboarNavbar/DashboardNavbar';
import axios from 'axios';
import FaultCodes from './FaultCodes';
import DiagnosticsData from './DiagnosticsData';

function Diagnostics() {
    const params = useParams();
    const [diagnosData, setDiagnosData] = useState([]);
    const [refresh,setRefresh] = useState(true);
    const [diagnostics,setDiagnostics] = useState('vehicle')
    const [diagnosProps,setDiagnosProps] = useState([]);

 

    const refreshData = () =>{
        setRefresh(refresh != true)
        
    }
    useEffect(() => {

        axios.get(`https://xertzdigital.net/diognostica.json`)
            .then(res => {

                if (res.status == 200) {
                    setDiagnosData(res.data);


                } else {
                    alert("Server not working ")
                }

            })
         
           
    }, [refresh]);

 
    

    const changeComponent = (value) =>{
        setDiagnostics(value)
    }

    return (
        <div className='dashboard pl-2 pr-2'>

            <Sidebar />

            <main className='main-content'>

                <Navbar />

                <div className='p-4 border-b-[2px] border-gray-300'>
                    {
                        
                        diagnosData.map((item, index) => {
                           
                            
                            return (
                                <div key={index} className='flex items-center justify-between'>
                                    <div className='flex items-center gap-4'>
                                        <div className=' pr-[10px] border-r-[2px] border-gray-300'>
                                            <small className='text-[16px] text-gray-500'>Driver:</small> <br />
                                            <h3 className='text-[20px] font-semibold'>{item.driver}</h3>
                                        </div>

                                        <div className=' pr-[10px] border-r-[2px] border-gray-300'>
                                            <small className='text-[16px] text-gray-500'>Make/Model:</small> <br />
                                            <h3 className='text-[20px] font-semibold'>{item.make_model}</h3>
                                        </div>

                                        <div className=' pr-[10px] border-r-[2px] border-gray-300'>
                                            <small className='text-[16px] text-gray-500'>Vin:</small> <br />
                                            <h3 className='text-[20px] font-semibold'>{item.vin}</h3>
                                        </div>

                                        <div className=' pr-[10px]'>
                                            <small className='text-[16px] text-gray-500'>Truck number:</small> <br />
                                            <h3 className='text-[20px] font-semibold'>{item.truck_number}</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-4'>
                                        <div className='flex items-center gap-4 border-[1px] p-2 rounded-lg bg-gray-50'>
                                            <p className='text-gray-500'>{item.data_time}</p>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66602 1.66663V4.16663" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.334 1.66663V4.16663" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M2.91602 7.57495H17.0827" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M17.5 7.08329V14.1666C17.5 16.6666 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6666 2.5 14.1666V7.08329C2.5 4.58329 3.75 2.91663 6.66667 2.91663H13.3333C16.25 2.91663 17.5 4.58329 17.5 7.08329Z" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.0781 11.4167H13.0856" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.0781 13.9167H13.0856" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.99607 11.4167H10.0036" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.99607 13.9167H10.0036" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.91209 11.4167H6.91957" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.91209 13.9167H6.91957" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </div>
                                        <button onClick={ () => refreshData()}>
                                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                                                <path d="M20.8901 11.08C20.0201 10.82 19.0601 10.65 18.0001 10.65C13.2101 10.65 9.33008 14.53 9.33008 19.32C9.33008 24.12 13.2101 28 18.0001 28C22.7901 28 26.6701 24.12 26.6701 19.33C26.6701 17.55 26.1301 15.89 25.2101 14.51" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M22.1302 11.32L19.2402 8" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M22.1298 11.3199L18.7598 13.7799" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>  

                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className='flex items-center gap-4 mt-4 text-[18px] cursor-pointer'>
                        <p onClick={() => changeComponent('vehicle')} style={{color:diagnostics == 'vehicle' ? '#1996F0':'black'}}>Vehicle diagnostics</p>
                        <p onClick={() => changeComponent('fault')} style={{color:diagnostics == 'fault' ? '#1996F0':'black'}}>Fault codes</p>
                    </div>

                </div>

                    {   
                    
                        diagnostics == 'vehicle' ?
                        <DiagnosticsData data={diagnosData}/> : <FaultCodes/>
                    }


            </main>


        </div>
    )
}

export default Diagnostics