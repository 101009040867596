import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../DashboarNavbar/DashboardNavbar';




function CreateVehicle() {
    const [checkPassword, setCheckPassword] = useState(true);
    const [formChange, setFormChange] = useState('driver');
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate()
    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(`https://superservice.uz/api/v1/accounts/company/drivers-short/`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
                    },
                });
                console.log("select", response);
                setData(response.data);

            } catch (err) {
                setError(err.message || 'Xato yuz berdi!');
            } finally {
                setLoading(false);
            }
        };

        if (accessToken) {
            fetchData();
        } else {
            setError('AccessToken topilmadi!');
            setLoading(false);
        }
    }, [accessToken]);






    // ---------------------------------------------------------------

    const [driverInfo, setDriverInfo] = useState({
        vehicle_number: "",
        license_plate: "",
        license_plate_state: "",
        fuel_type: '',
        make: "",
        model: "",
        vin: "",
        year: "",
        driver: "",
        eld_device_type: "",
        notes: "",
        eld_serial_number: ""
    });


    const params = useParams();

    useEffect(() =>{
      
        const fectData = async () =>{
            try{
                const response = await axios.get(`https://superservice.uz/api/v1/accounts/vehicle/${params.id}/`);
                setDriverInfo(response.data);
                
            }catch(e){
                console.log("error message",e);
                
            }
        }
        
        fectData()
        
        
        
    },[params.id])
    
    console.log("driver info",driverInfo);

    const handleEditPassword = async (e) => {
        e.preventDefault();



        setCheckPassword(true)
        try {
            const response = await axios.put(`https://superservice.uz/api/v1/accounts/vehicle/${params.id}/update/`, driverInfo);
            console.log("Data saved:", response.data);
            navigate('/vehicles')

            if (response.status == 400) {
                alert(response.data)
            }

        } catch (error) {
            console.error("Error saving data:", error);
        }

        // Agar "Save" bosilgan bo'lsa, API ga ma'lumotlarni yuboramiz


    };

    const handleInputChangePassword = (e) => {
        const { name, value } = e.target;
        setDriverInfo((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
        console.log(driverInfo);

    };






    return (
        <div className='dashboard pl-2 pr-2'>

            <Sidebar />

            <main className="main-content">
                <Navbar />

                <div className=' w-full pl-2 pr-2'>


                    <form onSubmit={handleEditPassword} className='p-3'>

                        <div className="flex items-center justify-between">
                            <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>Edit vehicle</h2>
                            <div className="flex items-center gap-3">
                                <Link to='/vehicles' className="edit-button">
                                    cansel
                                </Link>
                                <button className="edit-button" type="submit">
                                    Save
                                </button>
                            </div>
                        </div>



                        {/* ---------------------------------------------------------- */}

                        <div className='flex items-start justify-between gap-4'>
                            <div className="w-[33.3%]">

                                <div className="form-group">
                                    <label className='text-black'>Truck number (unit)</label>
                                    <input
                                        type="text"
                                        name="vehicle_number"
                                        value={driverInfo.vehicle_number}
                                        onChange={handleInputChangePassword}

                                        placeholder='Enter your Truck number'

                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        
                                    />
                                </div>

                                <div className="form-group">
                                    <label className='text-black'>License plate no</label>
                                    <input
                                        type="text"
                                        name="license_plate"
                                        value={driverInfo.license_plate}
                                        onChange={handleInputChangePassword}

                                        maxLength={100}
                                        placeholder='Enter your License plate'
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        
                                    />
                                </div>

                                <div className="form-group">
                                    <label>License plate issuing state</label>
                                    <input
                                        type="text"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.license_plate_state}
                                        name="license_plate_state"
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        placeholder='Enter your License plate issuing'
                                        
                                        minLength={1}
                                        maxLength={2}
                                    />

                                </div>

                                <div className="form-group">
                                    <label className='text-black'>Fuel type</label>
                                    <select
                                        onChange={handleInputChangePassword}
                                        name="fuel_type"
                                        value={driverInfo.fuel_type}                                        
                                        className='focus:outline-none border-[1px] bg-gray-100 rounded-lg text-gray-400'
                                    >
                                        <option value="" selected>Fuel type</option>
                                        <option value="Diesel">Diesel</option>
                                        <option value="Gasoline">Gasoline</option>
                                        <option value="Electric">Electric</option>
                                        <option value="Hybrid">Hybrid</option>
                                    </select>

                                </div>

                            </div>


                            <div className="w-[33.3%]">

                                <div className="form-group">
                                    <label className='text-black'>Make</label>
                                    <input
                                        type="text"
                                        name="make"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.make}
                                        placeholder='Enter your make'
                                        maxLength={100}
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        
                                    />
                                </div>

                                <div className="flex flex-col form-group">
                                    <label className='text-black font-semibold'>Model</label>
                                    <div className='w-full flex items-center justify-betwee'>
                                        <input
                                            type="text"
                                            name="model"
                                            onChange={handleInputChangePassword}
                                            value={driverInfo.model}
                                            maxLength={50}
                                            placeholder='Enter model'
                                            className='w-full bg-gray-100 border-none focus:outline-none placeholder:text-gray-400'
                                            
                                        />

                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>VIN</label>
                                    <input
                                        type="text"
                                        name="vin"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.vin}
                                        maxLength={50}
                                        placeholder='Enter vin'
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        
                                    />

                                </div>

                                <div className="form-group">
                                    <label className='text-black'>Year</label>
                                    <input
                                        type='number'
                                        name="year"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.year}
                                        placeholder='Enter your notes'
                                        maxLength={100}
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        
                                    />
                                </div>

                            </div>

                            <div className='w-[33.3%]'>


                                <div className="form-group">
                                    <label>Driver</label>
                                    <select onChange={handleInputChangePassword} name='driver' value={driverInfo.driver}>
                                        <option value="" selected>{driverInfo.driver}</option>
                                        {data.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.first_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label>Eld device</label>
                                    <select
                                        onChange={handleInputChangePassword}
                                        name="eld_device_type"
                                        value={driverInfo.eld_device_type}                                        
                                        className='focus:outline-none border-[1px] bg-gray-100 p-2 rounded-lg text-gray-400'
                                    >
                                        <option value="" selected>{driverInfo.eld_device_type}</option>
                                        <option value="tabble">Tabble</option>
                                        <option value="phone">Phone</option>
                                        <option value="laptob">Laptob</option>
                                    </select>

                                </div>

                                <div className="form-group">
                                    <label className='text-black'>Notes</label>
                                    <input
                                        type="text"
                                        name="notes"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.notes}
                                        placeholder='Enter your notes'
                                        maxLength={100}
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        
                                    />
                                </div>

                                <div className="form-group">
                                    <label className='text-black'>Eld serial number</label>
                                    <input
                                        type="text"
                                        name="eld_serial_number"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.eld_serial_number}
                                        placeholder='Eld serial number'
                                        maxLength={100}
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        
                                    />
                                </div>

                            </div>

                        </div>


                    </form>


                </div>
            </main>

        </div>
    )
}

export default CreateVehicle