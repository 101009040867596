import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';




function AdminCompanies() {

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
  
    // Foydalanuvchining accessToken-ni olish (misol uchun, mahalliy saqlashdan)
    const accessToken = localStorage.getItem('accessToken');
    const navigate = useNavigate()
    useEffect(() => {
      // API GET so'rovini yuboruvchi funksiya
      const fetchData = async () => {
        try {
          const response = await axios.get('https://superservice.uz/api/v1/accounts/companies/', {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
            },
          });
          setData(response.data.results);
        
        } catch (err) {
          setError(err.message || 'Xato yuz berdi!');
        } finally {
          setLoading(false);
        }
      };
  
      if (accessToken) {
        fetchData();
      } else {
        setError('AccessToken topilmadi!');
        setLoading(false);
      }
    }, [accessToken]);

    console.log(data);
    
    const getCompanyId = (id) =>{
        localStorage.setItem('companyId',id);
        navigate('/layout');
        
    }

    return (
        <div className='flex items-center justify-between'>

            <div className='form_container'>
                <h1 className='text-center text-[50px] font-bold mt-5' style={{color:"#1996F0"}}>Admin Companies</h1>
                <div className='company_list w-[80%] m-auto p-2 flex items-center justify-center border-1' style={{maxHeight:"80vh"}}>
                  {
                    data.map((item) =>{
                        return(
                            <div key={item.id} className='w-[70%] cursor-pointer' onClick={() => getCompanyId(item.id)}>
                                <div className='company_box w-full p-2 rounded-md' style={{background:"#1E2D4C"}}>
                                <div className='flex items-center gap-[20px] text-white'>
                                    <div>
                                    <span>Id: </span>
                                    <span>{item.id}</span>
                                    </div>

                                    <div>
                                        <span>Timezone: </span>
                                        <span>{item.timezone}</span>
                                    </div>

                                </div>

                                <div className='text-white mt-3'>
                                    <div>
                                       <span>Company name: </span>
                                       <span>{item.name}</span>
                                    </div>
                                    <div>
                                       <span>Company email: </span>
                                       <span>{item.email}</span>
                                    </div>
                                    <div>
                                       <span>Company timezone: </span>
                                       <span>{item.timezone}</span>
                                    </div>
                                </div>

                            </div>
                            </div>
                        )
                    })
                  }
                </div>
            </div>

            <div className="img_container">

            </div>

        </div>
    )
}

export default AdminCompanies;