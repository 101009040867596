import React, { useState, useEffect } from 'react'
import axios from 'axios';
 import { Link } from 'react-router-dom';
 import { Carousel } from 'react-responsive-carousel';


function FaultCodes() {


  const [logs, setLogs] = useState([]);
  const itemsPerPage = 5; // har bir sahifada ko'rsatish uchun elementlar soni
  let [page, setPage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://xertzdigital.net/fault_codes.json');
        setLogs(response.data);
      } catch (error) {
        console.error("Ma'lumotlarni yuklashda xatolik:", error);
      }
    };
    fetchData();
  }, []);

  const totalPages = Math.ceil(logs.length / itemsPerPage);
  const slides = [];
  const getPageData = () => {
    let next = 0;
     for (let i = 0; i < logs.length; i += 10) { 
       slides.push(logs.slice(i, i + 10));
        next++
       console.log(slides[next]);
       

  };

}
getPageData();





  return (
    <div className='p-3'>

 

      <Carousel showThumbs={false} showStatus={true}>
        {Array.from({ length: totalPages }, (_, i) => (
          <div key={i} className='w-full bg-white h-full'>



            

            <div className='w-full border-2 rounded-lg p-4'>


              <table style={{ height: '60vh', width: '100%' }}>
                <thead>
                  <tr className='font-normal text-sm border-b-2 pb-3' style={{ color: "#808080" }}>

                    <th>CODE NUMBER </th>
                    <th>MAKE/MODEL</th>
                    <th>STATUS</th>
                    <th>LAST UPDATE</th>
                    <th>RESEARCH</th>
                  
                  </tr>
                </thead>
                <tbody>

                  {slides[1].map((log, index) => (

                    <tr key={index} className='border-b-[2px]'>

                      <td>{log.code_number}</td>
                      <td>{log.make_model}</td>
                      <td><button className='text-white p-1 pl-2 pr-2 rounded-lg' style={{background: log.status ? '#5CB800':'#ED3237'}}>{log.status ? 'Active':'Unactive'}</button></td>
                      <td>{log.last_update}</td>

                      <td className=' h-full flex items-center justify-center'>
                        <a target='_blank' href={`https://www.google.com/search?q=${log.make_model}&rlz=1C1VDKB_enUZ1075UZ1075&oq=kun&gs_lcrp=EgZjaHJvbWUyDwgAEEUYORiDARixAxiABDIGCAEQRRg8MgoIAhAAGLEDGIAEMhAIAxAuGIMBGNQCGLEDGIAEMgoIBBAAGLEDGIAEMhAIBRAAGIMBGLEDGIAEGIoFMg0IBhAAGIMBGLEDGIAEMgYIBxAFGEDSAQkxMTE5NmowajSoAgiwAgE&sourceid=chrome&ie=UTF-8`}>
                          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="35" height="35" rx="4" fill="#E8F3FF" />
                            <path d="M17.5827 25.5001C21.9549 25.5001 25.4993 21.9557 25.4993 17.5834C25.4993 13.2112 21.9549 9.66675 17.5827 9.66675C13.2104 9.66675 9.66602 13.2112 9.66602 17.5834C9.66602 21.9557 13.2104 25.5001 17.5827 25.5001Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M26.3327 26.3334L24.666 24.6667" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>



                        </a>
                      </td>

                    </tr>

                  ))}

                </tbody>
              </table>
            </div>
          </div>
        ))}
      </Carousel>


    </div>
  )
}

export default FaultCodes