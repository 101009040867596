import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../DashboarNavbar/DashboardNavbar';
import passwordHideIcon from './../../Assets/signin/Component 39.png'
import passwordShowIcon from './../../Assets/signin/Component 38.png'
import { UserContext } from '../../../App';
import { adminCompanies, AdminData } from '../../Registration/AdminCompanies/AdminCompanies';
import { Link, useNavigate } from 'react-router-dom';



function AddDriver() {


    const [checkPassword, setCheckPassword] = useState(true);
    const [showPassword, setShowPassword] = useState(true);
    const [data, setData] = useState([]);


    const navigate = useNavigate()
    const companyId = localStorage.getItem('companyId');


    const [driverInfo, setDriverInfo] = useState({
        first_name: "",
        last_name: "",
        email: "",
        credential: "",
        password: "",
        notes: "",
        company: companyId,
        licence_state: "",
        phone: "",
        licence_number: "",
    });
    const handleEditPassword = async (e) => {
        e.preventDefault();



        setCheckPassword(true)
        try {
            const response = await axios.post("https://superservice.uz/api/v1/accounts/drivers/create/", driverInfo);
            console.log("Data saved:", response.data);
            navigate("/driver")
            // Ma'lumotlar saqlandi, tahrirlash rejimini o'chiramiz

        } catch (error) {
            console.error("Error saving data:", error);
            alert("An error occurred while saving the data.")
        }

        // Agar "Save" bosilgan bo'lsa, API ga ma'lumotlarni yuboramiz


    };

    const handleInputChangePassword = (e) => {
        const { name, value } = e.target;
        setDriverInfo((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));





    };




    return (
        <div className='dashboard pl-2 pr-2'>

            <Sidebar />


            <main className="main-content">
                <Navbar />
                <form onSubmit={handleEditPassword} className='p-3'>

                    <div className="flex items-center justify-between">
                        <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>New driver</h2>
                        <div className="flex items-center gap-3">
                            <Link to='/driver' className="edit-button" >
                                cansel
                            </Link>
                            <button className="edit-button" type="submit">
                                Save
                            </button>
                        </div>
                    </div>

                    <div className="border-b-2 pb-2 mb-[30px] ">

                        <span className="tab border-b-2 pb-2 font-semibold" style={{ borderColor: '#1996F0', color: '#1996F0' }}>Driver informations</span>
                    </div>

                    {/* ---------------------------------------------------------- */}

                    <div className='flex items-start justify-between gap-4'>
                        <div className="w-[33.3%]">

                            <div className="form-group">
                                <label className=''>First name</label>
                                <input
                                    type="text"
                                    name="first_name"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your first name'

                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className=''>Last name</label>
                                <input
                                    type="text"
                                    name="last_name"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your first name'

                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label className=''>Email address</label>
                                <input
                                    type="email"
                                    name="email"

                                    onChange={handleInputChangePassword}

                                    maxLength={100}
                                    placeholder='Enter your email'
                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>



                        </div>


                        <div className="w-[33.3%]">



                            <div className="form-group">
                                <label className=''>Password</label>
                                <div className='w-full flex items-center justify-between bg-gray-100 border-[1px] pr-1 rounded-md'>
                                    <input
                                        type={showPassword ? "password" : 'text'}
                                        name="password"
                                        style={{ border: "none" }}
                                        onChange={handleInputChangePassword}

                                        maxLength={50}
                                        className='text-black w-full focus:outline-none placeholder:text-gray-400 bg-transparent '
                                        required
                                    />
                                    <img src={showPassword ? passwordHideIcon : passwordShowIcon} alt="" width={20} onClick={() => setShowPassword(showPassword != true)} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className=''>Credential</label>
                                <input
                                    type="text"
                                    name="credential"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your login name'

                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label className=''>Notes</label>
                                <input
                                    type="text"
                                    name="notes"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your notes'
                                    maxLength={100}
                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>



                        </div>

                        <div className='w-[33.3%]'>
                            <div className="form-group">
                                <label className=''>Phone number</label>
                                <input
                                    type="text"
                                    name="phone"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your phone number'
                                    maxLength={100}
                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className=''>Drivers license number</label>
                                <input
                                    type="text"
                                    name="licence_number"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your license number'
                                    maxLength={100}
                                    className=' text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>

                            <div className='form-group'>
                                <label htmlFor="company">Driver's license issuing state</label>
                                <input
                                    type="text"

                                    name="licence_state"
                                    id="company"                            
                                    onChange={handleInputChangePassword}
                                    placeholder='Enter your license state'
                                    maxLength={2}
                                    minLength={1}
                                    className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400 text-black'
                                    required
                                />
                            </div>

                        </div>

                    </div>


                </form>
            </main>

        </div>
    )
}

export default AddDriver

