import React, { useEffect, useState } from 'react'
import { Flat, Heat, Nested } from '@alptugidin/react-circular-progress-bar'

function DiagnosticsData({ data }) {
    const [propsEl, setPropsEl] = useState([])
    useEffect(() => {
        data.map((item) => {
            setPropsEl(item.diognostics)
        });


    }, [data]);

    return (
        <div className='p-4'>
            {
                propsEl.map((item, index) => {

                    return (
                        <div key={index} >

                            <div className='flex items-center justify-between flex-wrap gap-1 mb-5'>

                                <div className=' w-[33%] xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg'>
                                    <p className='text-[16] text-gray-500'>Fuel economy</p>
                                    <h2 className='text-[24px] font-semibold'>{item.fuel_economy}</h2>
                                </div>
                                <div className=' w-[33%] xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg'>
                                    <p className='text-[16] text-gray-500'>Fuel gallon used</p>
                                    <h2 className='text-[24px] font-semibold'>{item.fuel_gallon}</h2>
                                </div>
                                <div className=' w-[33%] xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg'>
                                    <p className='text-[16] text-gray-500'>Total driving time</p>
                                    <h2 className='text-[24px] font-semibold'>{item.total_driving_time}</h2>
                                </div>
                                <div className=' w-[33%] xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg'>
                                    <p className='text-[16] text-gray-500'>Total idle time</p>
                                    <h2 className='text-[24px] font-semibold'>{item.total_idle_time}</h2>
                                </div>

                            </div>


                            <div className='flex items-center justify-between flex-wrap gap-1 mb-5'>

                                <div className=' xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg flex items-center justify-between'>
                                    <div>
                                        <p className='text-[16] text-gray-500'>Fuel level</p>
                                        <h2 className='text-[24px] font-semibold'>{item.fuel_level}%</h2>
                                    </div>

                                    <div className='w-[70px]'>
                                        <Flat
                                            progress={item.fuel_level}
                                            showValue={false}
                                            sx={{
                                                strokeColor: '#1996f0',
                                                bgStrokeColor: '#e8f3ff',
                                                barWidth: 10,
                                                strokeLinecap:10,
                                                bgColor: { value: '#fefbfb', transparency: '10' },
                                                valueFamily: 'Lucida Grande',
                                                miniCircleColor: '#a65959',
                                                miniCircleSize: 0,
                                                valueAnimation: false
                                            }}
                                        />
                                    </div>
                                </div>



                                <div className=' xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg flex items-center justify-between'>
                                    <div>
                                        <p className='text-[16] text-gray-500'>Battery voltage</p>
                                        <h2 className='text-[24px] font-semibold'>{item.battery_voltage}V</h2>
                                    </div>

                                    <div className='w-[70px]'>
                                        <Flat
                                            progress={item.battery_voltage}
                                            range={{ from: 0, to: 18 }}
                                            showValue={false}
                                            sx={{
                                                strokeColor: '#1996f0',
                                                bgStrokeColor: '#e8f3ff',
                                                barWidth: 10,
                                                strokeLinecap:10,
                                                bgColor: { value: '#fefbfb', transparency: '10' },
                                                valueFamily: 'Lucida Grande',
                                                miniCircleColor: '#a65959',
                                                miniCircleSize: 0,
                                                valueAnimation: false
                                            }}
                                        />
                                    </div>
                                </div>



                                <div className=' xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg flex items-center justify-between'>
                                    <div>
                                        <p className='text-[16] text-gray-500'>Engine coolant temperature</p>
                                        <h2 className='text-[24px] font-semibold'>{item.engine_coolan}F</h2>
                                    </div>

                                    <div className='w-[70px]'>
                                        <Flat
                                            progress={item.engine_coolan}
                                            range={{ from: 0, to: 300 }}
                                            showValue={false}
                                            sx={{
                                                strokeColor: '#1996f0',
                                                bgStrokeColor: '#e8f3ff',
                                                barWidth: 10,
                                                strokeLinecap:10,
                                                bgColor: { value: '#fefbfb', transparency: '10' },
                                                valueFamily: 'Lucida Grande',
                                                miniCircleColor: '#a65959',
                                                miniCircleSize: 0,
                                                valueAnimation: false
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className=' xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg flex items-center justify-between'>
                                    <div>
                                        <p className='text-[16] text-gray-500'>Engine coolant level</p>
                                        <h2 className='text-[24px] font-semibold'>{item.endine_coolan_level}%</h2>
                                    </div>

                                    <div className='w-[70px]'>
                                        <Flat
                                            progress={item.endine_coolan_level}
                                            range={{ from: 0, to: 100 }}
                                            showValue={false}
                                            sx={{
                                                strokeColor: '#1996f0',
                                                bgStrokeColor: '#e8f3ff',
                                                barWidth: 10,
                                                strokeLinecap:10,
                                                bgColor: { value: '#fefbfb', transparency: '10' },
                                                valueFamily: 'Lucida Grande',
                                                miniCircleColor: '#a65959',
                                                miniCircleSize: 0,
                                                valueAnimation: false
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>

                            <div className='flex items-center flex-wrap gap-4 mb-5'>

                            <div className=' xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg flex items-center justify-between'>
                                    <div>
                                        <p className='text-[16] text-gray-500'>Engine oil temperature</p>
                                        <h2 className='text-[24px] font-semibold'>{item.engine_oil_temperature}F</h2>
                                    </div>

                                    <div className='w-[70px]'>
                                        <Flat
                                            progress={item.engine_oil_temperature}
                                            range={{ from: 0, to: 300 }}
                                            showValue={false}
                                            sx={{
                                                strokeColor: '#1996f0',
                                                bgStrokeColor: '#e8f3ff',
                                                barWidth: 10,
                                                strokeLinecap:10,
                                                bgColor: { value: '#fefbfb', transparency: '10' },
                                                valueFamily: 'Lucida Grande',
                                                miniCircleColor: '#a65959',
                                                miniCircleSize: 0,
                                                valueAnimation: false
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className=' xl:w-[33%] 2xl:w-[24%] p-2 border-[2px] border-gray-300 rounded-lg flex items-center justify-between'>
                                    <div>
                                        <p className='text-[16] text-gray-500'>Transmission oil temperature</p>
                                        <h2 className='text-[24px] font-semibold'>{item.transmission_oil}F</h2>
                                    </div>

                                    <div className='w-[70px]'>
                                        <Flat
                                            progress={item.transmission_oil}
                                            range={{ from: 0, to: 300 }}
                                            showValue={false}
                                            sx={{
                                                strokeColor: '#1996f0',
                                                bgStrokeColor: '#e8f3ff',
                                                barWidth: 10,
                                                strokeLinecap:10,
                                                bgColor: { value: '#fefbfb', transparency: '10' },
                                                valueFamily: 'Lucida Grande',
                                                miniCircleColor: '#a65959',
                                                miniCircleSize: 0,
                                                valueAnimation: false
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>



                        </div>
                    )
                })
            }
        </div>
    )
}

export default DiagnosticsData