import React, { useContext, useState } from "react";
import axios from "axios";
import "./profile.css";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../DashboarNavbar/DashboardNavbar";
import { UserContext } from "../../../App";

const ProfileEdit = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [passwordSave, setPasswordSave] = useState(false);
    const [hide, setHide] = useState(true);
    const [checkPassword, setCheckPassword] = useState(true);
    
    const [profile, setProfile] = useState({
        firstName: "your name",
        secondName: "second name",
        phoneNumber: "+998 93 142-90-20",
    });

    const [changePassword, setChangePassword] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const handleEditClick = async (e) => {
        e.preventDefault();
        if (isEditing) {
            // Agar "Save" bosilgan bo'lsa, API ga ma'lumotlarni yuboramiz
            try {
                const response = await axios.post("https://671747adb910c6a6e027483d.mockapi.io/register/user", profile);
                console.log("Data saved:", response.data);
                // Ma'lumotlar saqlandi, tahrirlash rejimini o'chiramiz
                setIsEditing(false);
            } catch (error) {
                console.error("Error saving data:", error);
            }
        } else {
            // "Edit" tugmasi bosilganda faqat tahrirlash rejimiga o'tadi
            setIsEditing(true);
        }
    };

    const handleEditPassword = async (e) => {
        e.preventDefault();
        if (passwordSave) {
            if(changePassword.newPassword === changePassword.confirmPassword){

                setCheckPassword(true)
                try {
                    const response = await axios.post("https://671747adb910c6a6e027483d.mockapi.io/register/user", changePassword);
                    console.log("Data saved:", response.data);
                    alert("your password succesfuly changed")
                    // Ma'lumotlar saqlandi, tahrirlash rejimini o'chiramiz
                    setPasswordSave(false);
                } catch (error) {
                    console.error("Error saving data:", error);
                }
            }
            else{
                
                setCheckPassword(false)
            }
            // Agar "Save" bosilgan bo'lsa, API ga ma'lumotlarni yuboramiz
         
        } else {
            // "Edit" tugmasi bosilganda faqat tahrirlash rejimiga o'tadi
            
            setPasswordSave(true);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    };
    const handleInputChangePassword = (e) => {
        const { name, value } = e.target;
        setChangePassword((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    };
    const user = useContext(UserContext)

    return (
        <div className="dashboard pl-2 pr-2" >
            <Sidebar />

            <main className="main-content">
                <Navbar />
                {/* /////////////////////////////////////////////// */}

                <div className="profile-edit" style={{background:user.night ? '#fff' : "#1E2D4C",color:user.night ? '' : "#fff",transition:'all 0.3s ease'}}>



                    {
                        hide ? (
                            <form onSubmit={handleEditClick}>
                                <div className="flex items-center justify-between">
                                    <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>Profile</h2>
                                    <button className="edit-button" type="submit">
                                        {isEditing ? "Save" : "Edit"}
                                    </button>
                                </div>

                                <div className="tabs flex items-center justify-between border-b-2 ">
                                    <div className="flex gap-4">
                                        <span className="tab " onClick={() => setHide(true)} style={{color:user.night ? '' : "white"}}>Edit profile</span>
                                        <span className="tab" onClick={() => setHide(false)} style={{color:user.night ? '' : "white"}}>Change password</span>
                                    </div>
                                </div>
                                <div className="profile-form">
                                    <div className="form-group">
                                        <label>First name</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            placeholder={profile.firstName}
                                            onChange={handleInputChange}
                                            disabled={!isEditing}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Second name</label>
                                        <input
                                            type="text"
                                            name="secondName"
                                            placeholder={profile.secondName}
                                            onChange={handleInputChange}
                                            disabled={!isEditing}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Phone number</label>
                                        <input
                                            type="text"
                                            name="phoneNumber"
                                            placeholder={profile.phoneNumber}
                                            onChange={handleInputChange}
                                            disabled={!isEditing}
                                            required
                                        />
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={handleEditPassword}>
                                <div className="flex items-center justify-between">
                                    <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>Profile</h2>
                                    <div className="flex items-center gap-3">
                                        <p className="edit-button" onClick={() => setChangePassword(changePassword.oldPassword = "" )}>
                                            cansel
                                        </p>
                                        <button className="edit-button" type="submit">
                                        {passwordSave ? "Save" : "Edit"}
                                        </button>
                                    </div>
                                </div>

                                <div className="tabs flex items-center justify-between border-b-2">
                                    <div className="flex gap-4">
                                        <span className="tab " onClick={() => setHide(true)} style={{color:user.night ? '' : "white"}}>Edit profile</span>
                                        <span className="tab" onClick={() => setHide(false)} style={{color:user.night ? '' : "white"}}>Change password</span>
                                    </div>
                                </div>
                                <div className="profile-form">

                                    <div className="form-group">
                                        <label>Old password</label>
                                        <input
                                            type="password"
                                            name="oldPassword"
                                            value={changePassword.oldPassword}
                                            onChange={handleInputChangePassword}
                                            disabled={!passwordSave}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input
                                            type="password"
                                            name="newPassword"
                                            value={changePassword.newPassword}
                                            onChange={handleInputChangePassword}
                                            disabled={!passwordSave}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            value={changePassword.confirmPassword}
                                            onChange={handleInputChangePassword}
                                            disabled={!passwordSave}
                                            required
                                        />
                                        <span className={`text-xs text-red-600 ${checkPassword ? 'hidden': 'block'}`}>check again</span>
                                    </div>

                                </div>
                            </form>
                        )
                    }

                </div>

                {/* /////////////////////////////////////////////// */}
            </main>
        </div>

    );
};

export default ProfileEdit;
