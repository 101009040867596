// Login.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './singup.css'





const SignUp = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');

    const navigate = useNavigate()
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://671747adb910c6a6e027483d.mockapi.io/register/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({fullName,email}),
            });
            localStorage.setItem("userEmail",email);

            const data = await response.json();
            if (response.ok) {
                console.log(data);
               // localStorage.setItem("userPassword", password) //backend bitganda tokenni saqlab olish uchun qildim 
                navigate("/SignUpVerification")

            } else {
                alert('Login failed!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert("not corrent password or email")
        }
    };
    
    

    return (
        <header className='signin_header'>
            <div className="row_box">
                <div className="form_container flex items-center justify-center">
                    <form onSubmit={handleLogin} className='w-7/12 min-w-96 p-10'>
                        <div className='mb-8'>
                            <p className='text-base'>Wellcome</p>
                            <h3 className='text-5xl font-semibold' style={{ color: "#1996F0" }}>Please sign Up</h3>
                        </div>

                        <div className='mb-5'>
                            <label htmlFor="userFullName" className='mb-4 text-base'>Full Name</label>

                            <input
                                type="text"
                                placeholder="Enter your full name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                id='userFullName'
                                className='w-full rounded-xl p-2'
                                maxLength={60}
                                minLength={4}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="userpassword" className='mb-4 text-base'>Email address</label>
                            <div className='flex items-center justify-between bg-white p-2 rounded-xl mb-4'>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    id='userEmail'
                                    className='w-full bg-transparent'
                                    maxLength={30}
                                    minLength={4}
                                    required
                                   
                                />
                                
                            </div>
                        </div>

                        <button type="submit" className='w-full text-white text-center p-2 rounded-xl' style={{ background: "#1996F0" }}>Verify</button>

                    </form>
                </div>
                <div className="img_container">

                </div>
            </div>
        </header>
    );
};

export default SignUp;
