// Login.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './signin.css'
import passwordHideIcon from './../../Assets/signin/Component 39.png'
import passwordShowIcon from './../../Assets/signin/Component 38.png'





const SignIn = () => {
    const [credential, setCredential] = useState('');
    const [password, setPassword] = useState('');
    const [rememberPassword, setRememberPassword] = useState(true);
    const [showPassword, setShowPassword] = useState(true);
    const [errorPassword, setErrorPassword] = useState(true)
    const navigate = useNavigate()


    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://superservice.uz/api/v1/accounts/login-web/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ credential, password }),
            });



            const data = await response.json();
            if (response.ok) {

                localStorage.setItem("accessToken", data.access);
                localStorage.setItem("refreshToken", data.refresh)
                navigate('/admincompanies')

            } else {
                alert('Login failed!');
                setErrorPassword(false);
            }
        } catch (error) {
            console.error('Error:', error);
            alert("not corrent password or email")
        }
    };



    return (
        <header className='signin_header'>
            <div className="row_box">
                <div className="form_container flex items-center justify-center">
                    <form onSubmit={handleLogin} className='w-7/12 min-w-96 p-10'>

                        <div  className='flex items-center gap-5'>
                            <svg width="62" height="56" viewBox="0 0 62 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M61.5385 55.1494L3.61991 55.1494C1.62069 55.1494 -7.48771e-06 53.5287 -7.31293e-06 51.5295L-5.20318e-06 27.3968C-5.0284e-06 25.3975 1.62068 23.7769 3.61991 23.7769L30.1659 23.7769C47.4925 23.7769 61.5385 37.8228 61.5385 55.1494Z" fill="#1996F0" />
                                <path d="M31.3726 18.9501L1.20671 18.9501C0.5403 18.9501 7.25265e-05 18.4099 7.25847e-05 17.7435L7.39832e-05 1.74694C7.40265e-05 1.2519 0.401388 0.850583 0.896433 0.850583L13.2731 0.850586C23.2692 0.850587 31.3726 8.95403 31.3726 18.9501Z" fill="black" />
                            </svg>
                            
                            <h2 className='text-[30px] font-bold' style={{color:"#1996F0"}}>
                                ELD <br />
                                <span className='text-black'>LOGISTICS</span>
                            </h2>

                        </div>

                        <div className='mb-8'>
                            <p className='text-base'>Wellcome</p>
                            <h3 className='text-5xl font-semibold' style={{ color: "#1996F0" }}>Please sign in</h3>
                        </div>

                        <div className='mb-5'>
                            <label htmlFor="useremail" className='mb-4 text-base'>Email Address</label>

                            <input
                                type="email"
                                placeholder="Enter email"
                                name='credential'
                                value={credential}
                                onChange={(e) => setCredential(e.target.value)}
                                id='useremail'
                                className='w-full rounded-xl p-2'
                                maxLength={60}
                                minLength={4}
                                required
                            />

                        </div>

                        <div>
                            <label htmlFor="userpassword" className='mb-4 text-base'>Password</label>
                            <div className='flex items-center justify-between bg-white p-2 rounded-xl mb-4'>
                                <input
                                    type={showPassword ? 'password' : "text"}
                                    placeholder="Enter password"
                                    name='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    id='userpassword'
                                    className={`w-full bg-transparent ${errorPassword ? 'text-black' : 'text-red-600'}`}
                                    maxLength={30}
                                    minLength={4}
                                    required

                                />
                                <img src={showPassword ? passwordHideIcon : passwordShowIcon} alt="" onClick={() => setShowPassword(showPassword != true)} />
                            </div>
                            <p className={`text-red-500 mb-4 ${errorPassword ? 'hidden' : 'block'}`}>Incorrect password</p>
                        </div>

                        <div className='flex items-center justify-between mb-5'>
                            <div className='flex items-center justify-between w-2/4'>
                                <input type="checkbox" id='password-check' onClick={() => setRememberPassword(rememberPassword != true)
                                } style={{ width: "20px", height: "20px" }} />
                                <label htmlFor="password-check">Remember password</label>
                            </div>
                            
                        </div>

                        <button type="submit" className='w-full text-white text-center p-2 rounded-xl' style={{ background: "#1996F0" }}>Sign In</button>
                        
                        <div className='mt-4'>
                            <small className='text-[16px]'>Need help?</small>
                            <p className='text-[20px] font-[600]' style={{color:'#1996F0'}}>Call us:+1 777 77 77 77</p>
                        </div>
                    </form>
                </div>
                <div className="img_container">

                </div>
            </div>
        </header>
    );
};

export default SignIn;
