import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../DashboarNavbar/DashboardNavbar'
import TrackingDoc from './TrackingDoc'

function TrackingLayout() {
  return (
    <div className='dashboard pl-2 pr-2'>


        <Sidebar/>

        <main className="main-content">
            <div className='main-content'>
            <Navbar/>
            </div>
            <TrackingDoc/>
        </main>

    </div>
  )
}

export default TrackingLayout