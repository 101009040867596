 import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../DashboarNavbar/DashboardNavbar'
import Vehisles from './Vehicles'
 
 function VehiclesLayout() {
   return (
     <div className='dashboard pl-2 pr-2'>

    <Sidebar/>

        <main className="main-content">
            <div className='main-content'>
            <Navbar/>
            </div>
            <Vehisles/>
        </main>

     </div>
   )
 }
 
 export default VehiclesLayout