import React, { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../DashboarNavbar/DashboardNavbar';
import DriverProgression from './DriverProgression';

function DriverDoc() {
    const params = useParams();
    const [driver,setDriver] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://jsonplaceholder.typicode.com/users/${params.id}`);
                setDriver(response.data);
            } catch (error) {
                console.error("Ma'lumotlarni yuklashda xatolik:", error);
            }
        };
        fetchData();
    }, [params]);

    
    
    
  return (
    <div className="dashboard pl-2 pr-2" style={{ background: "#E6E6E6" }}>

        <Sidebar/>

        <main className="main-content">
            <Navbar/>
             

          <div className='general_progression_box'>
          <DriverProgression paramsId={params}/>
          </div>


        </main>

    </div>
  )
}

export default DriverDoc;