import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import DropDownIcon from '../../Assets/Sidebar/arrow-left.png'
import refresh2 from '../../Assets/Sidebar/refresh-2.png'
import User from '../../Assets/Sidebar/user.png'
import briefcase from '../../Assets/Sidebar/briefcase.png'
import logout from '../../Assets/Sidebar/logout.png'
import { UserContext } from '../../../App'

function Sidebar() {

    const [activeLink, setActiveLink] = useState('');
    const [closeBar, setCloseBar] = useState(true);


    const handleLinkClick = (link) => {
        setActiveLink(link);

    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const user = useContext(UserContext)
    return (
        <div >
            <aside className={`${closeBar ? "sidebar" : "closeBar"}`} style={{background:user.night ? '#fff' : "#1E2D4C",color:user.night ? '' : "#fff",transition:'all 0.3s ease'}}>

                <div>
                    <div className='w-full flex items-center justify-between'>
                        <div className='flex items-center gap-2'>
                            <svg width="37" height="33" viewBox="0 0 37 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M36.9229 32.8146L2.17172 32.8146C0.972187 32.8146 -0.000224982 31.8422 -0.000224877 30.6426L-0.000223611 16.163C-0.000223507 14.9635 0.972189 13.991 2.17172 13.991L18.0993 13.991C28.4953 13.991 36.9229 22.4186 36.9229 32.8146Z" fill="#1996F0" />
                                <path d="M18.8237 11.095L0.724184 11.095C0.324339 11.095 0.000202207 10.7709 0.000202242 10.371L0.000203081 0.773101C0.000203107 0.476074 0.240991 0.235286 0.538018 0.235286L7.964 0.235288C13.9617 0.235288 18.8237 5.09735 18.8237 11.095Z" fill="black" />
                            </svg>
                            <h2 className={`text-base font-extrabold ${closeBar ? 'block' : "hidden"}`}><span style={{ color: "#1996F0" }}>ELD</span> <br /> Logistics</h2>
                        </div>
                        <button onClick={() => setCloseBar(closeBar != true)}>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="36" height="36" rx="4.8" fill="#E8F3FF" />
                                <path d="M20.9998 25.92L14.4798 19.4C13.7098 18.63 13.7098 17.37 14.4798 16.6L20.9998 10.08" stroke="#1996F0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>

                    <nav className='w-full h-full flex justify-center'>
                        <ul className='w-full'>
                            <div className='mt-10'>

                                <Link to='/layout'>
                                <li className={`flex items-center gap-2 p-2 rounded-lg ${activeLink === 'dashboard' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('dashboard')}>
                                    <Link to='/layout'><svg width="20" height="20"  viewBox="0 0 16 16" fill={`${user.night ? "none" : "white"}`} xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.33398 6.66665H4.66732C6.00065 6.66665 6.66732 5.99998 6.66732 4.66665V3.33331C6.66732 1.99998 6.00065 1.33331 4.66732 1.33331H3.33398C2.00065 1.33331 1.33398 1.99998 1.33398 3.33331V4.66665C1.33398 5.99998 2.00065 6.66665 3.33398 6.66665Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.334 6.66665H12.6673C14.0007 6.66665 14.6673 5.99998 14.6673 4.66665V3.33331C14.6673 1.99998 14.0007 1.33331 12.6673 1.33331H11.334C10.0007 1.33331 9.33398 1.99998 9.33398 3.33331V4.66665C9.33398 5.99998 10.0007 6.66665 11.334 6.66665Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.334 14.6666H12.6673C14.0007 14.6666 14.6673 14 14.6673 12.6666V11.3333C14.6673 9.99998 14.0007 9.33331 12.6673 9.33331H11.334C10.0007 9.33331 9.33398 9.99998 9.33398 11.3333V12.6666C9.33398 14 10.0007 14.6666 11.334 14.6666Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.33398 14.6666H4.66732C6.00065 14.6666 6.66732 14 6.66732 12.6666V11.3333C6.66732 9.99998 6.00065 9.33331 4.66732 9.33331H3.33398C2.00065 9.33331 1.33398 9.99998 1.33398 11.3333V12.6666C1.33398 14 2.00065 14.6666 3.33398 14.6666Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    </Link>
                                    <Link to='/layout' className={`text-base font-normal ${closeBar ? 'block' : 'hidden'}`}>Dashboard</Link>
                                </li>
                                </Link>

                                <Link to='/logs'>
                                <li className={`flex items-center gap-2 p-2 rounded-lg ${activeLink === 'logs' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('logs')}>
                                    <Link to='/logs'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill={`${user.night ? "none" : "white"}`} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.3332 8.33335V12.5C18.3332 16.6667 16.6665 18.3334 12.4998 18.3334H7.49984C3.33317 18.3334 1.6665 16.6667 1.6665 12.5V7.50002C1.6665 3.33335 3.33317 1.66669 7.49984 1.66669H11.6665" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M18.3332 8.33335H14.9998C12.4998 8.33335 11.6665 7.50002 11.6665 5.00002V1.66669L18.3332 8.33335Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.8335 10.8334H10.8335" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.8335 14.1667H9.16683" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </Link>

                                    <Link to='/logs' className={`text-base font-normal ${closeBar ? 'block' : 'hidden'}`}>Logs</Link>
                                </li>
                                </Link>

                               <Link to='/tracking'>
                                 <li
                                    className={`flex items-center gap-2 p-2 rounded-lg ${activeLink === 'tracking' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('tracking')}>
                                    <Link to='/tracking'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill={`${user.night ? "none" : "white"}`} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 18.3334V16.6667" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10 15V13.3334" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10 11.6667V9.16671C10 5.94171 12.6083 3.33337 15.8333 3.33337H18.3333" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1.6665 3.33337H4.1665C7.3915 3.33337 9.99984 5.94171 9.99984 9.16671V10V11.6667" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M3.33317 1.66669L1.6665 3.33335L3.33317 5.00002" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.6665 1.66669L18.3332 3.33335L16.6665 5.00002" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </Link>

                                    <Link to='/tracking' className={`text-base font-normal ${closeBar ? 'block' : 'hidden'}`}>Tracking</Link>
                                </li>
                               </Link>
                            </div>

                            <div className=''>

                              <Link to='/driver'>
                              <li className={`flex items-center gap-2 p-2 rounded-lg ${activeLink === 'drivers' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('drivers')}>
                                    <Link to='/driver'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill={`${user.night ? "none" : "white"}`} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.1665 17.5H5.83317C2.49984 17.5 1.6665 16.6667 1.6665 13.3334V6.6667C1.6665 3.33336 2.49984 2.50003 5.83317 2.50003H14.1665C17.4998 2.50003 18.3332 3.33336 18.3332 6.6667V13.3334C18.3332 16.6667 17.4998 17.5 14.1665 17.5Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M11.6665 6.66669H15.8332" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.5 10H15.8333" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M14.1665 13.3334H15.8332" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.08353 9.40836C7.91656 9.40836 8.59186 8.73306 8.59186 7.90003C8.59186 7.067 7.91656 6.39169 7.08353 6.39169C6.2505 6.39169 5.5752 7.067 5.5752 7.90003C5.5752 8.73306 6.2505 9.40836 7.08353 9.40836Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.99984 13.6084C9.88317 12.4 8.92484 11.45 7.7165 11.3417C7.29984 11.3 6.87484 11.3 6.44984 11.3417C5.2415 11.4584 4.28317 12.4 4.1665 13.6084" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </Link>

                                    <Link to='/driver' className={`text-base font-normal ${closeBar ? 'block' : 'hidden'}`}>Drivers</Link>
                                </li>
                              </Link>

                                <Link to='/vehicles'>
                                <li className={`flex items-center gap-2 p-2 rounded-lg ${activeLink === 'vehicles' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('vehicles')}>
                                    <Link to='/vehicles'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill={`${user.night ? "none" : "white"}`} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.4998 1.66669V10C12.4998 10.9167 11.7498 11.6667 10.8332 11.6667H1.6665V5.00002C1.6665 3.15835 3.15817 1.66669 4.99984 1.66669H12.4998Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M18.3332 11.6667V14.1667C18.3332 15.55 17.2165 16.6667 15.8332 16.6667H14.9998C14.9998 15.75 14.2498 15 13.3332 15C12.4165 15 11.6665 15.75 11.6665 16.6667H8.33317C8.33317 15.75 7.58317 15 6.6665 15C5.74984 15 4.99984 15.75 4.99984 16.6667H4.1665C2.78317 16.6667 1.6665 15.55 1.6665 14.1667V11.6667H10.8332C11.7498 11.6667 12.4998 10.9167 12.4998 10V4.16669H14.0332C14.6332 4.16669 15.1832 4.4917 15.4832 5.00836L16.9082 7.50002H15.8332C15.3748 7.50002 14.9998 7.87502 14.9998 8.33335V10.8334C14.9998 11.2917 15.3748 11.6667 15.8332 11.6667H18.3332Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6.66667 18.3334C7.58714 18.3334 8.33333 17.5872 8.33333 16.6667C8.33333 15.7462 7.58714 15 6.66667 15C5.74619 15 5 15.7462 5 16.6667C5 17.5872 5.74619 18.3334 6.66667 18.3334Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.3332 18.3334C14.2536 18.3334 14.9998 17.5872 14.9998 16.6667C14.9998 15.7462 14.2536 15 13.3332 15C12.4127 15 11.6665 15.7462 11.6665 16.6667C11.6665 17.5872 12.4127 18.3334 13.3332 18.3334Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M18.3333 10V11.6667H15.8333C15.375 11.6667 15 11.2917 15 10.8334V8.33336C15 7.87503 15.375 7.50003 15.8333 7.50003H16.9083L18.3333 10Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </Link>

                                    <Link to='/vehicles' className={`text-base font-normal ${closeBar ? 'block' : 'hidden'}`}>Vehicles</Link>
                                </li>
                                </Link>

                                <Link to='/resources'>
                                <li className={`flex items-center gap-2 p-2 rounded-lg ${activeLink === 'resources' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('resources')}>
                                    <Link to='/resources'>
                                        <svg width="20" height="20" viewBox="0 0 16 16" fill={`${user.night ? "none" : "white"}`} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.6663 7.33331V11.3333C14.6663 14 13.9997 14.6666 11.333 14.6666H4.66634C1.99967 14.6666 1.33301 14 1.33301 11.3333V4.66665C1.33301 1.99998 1.99967 1.33331 4.66634 1.33331H5.66634C6.66634 1.33331 6.88634 1.62665 7.26634 2.13331L8.26634 3.46665C8.51967 3.79998 8.66634 3.99998 9.33301 3.99998H11.333C13.9997 3.99998 14.6663 4.66665 14.6663 7.33331Z" stroke="black" stroke-miterlimit="10" />
                                            <path d="M5.33301 1.33331H11.333C12.6663 1.33331 13.333 1.99998 13.333 3.33331V4.25331" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>


                                    </Link>

                                    <Link to='/resources' className={`text-base font-normal ${closeBar ? 'block' : 'hidden'}`}>Resources</Link>
                                </li>
                                </Link>

                                <Link to='/reports'>
                                <li className={`flex items-center gap-2 p-2 rounded-lg ${activeLink === 'reports' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('reports')}>
                                    <Link to='/reports'>
                                        <svg width="20" height="20" viewBox="0 0 16 16" fill={`${user.night ? "none" : "white"}`} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3337 5.50004V12C13.3337 14 12.1403 14.6667 10.667 14.6667H5.33366C3.86033 14.6667 2.66699 14 2.66699 12V5.50004C2.66699 3.33337 3.86033 2.83337 5.33366 2.83337C5.33366 3.24671 5.50031 3.62004 5.77364 3.89337C6.04697 4.1667 6.42033 4.33337 6.83366 4.33337H9.16699C9.99366 4.33337 10.667 3.66004 10.667 2.83337C12.1403 2.83337 13.3337 3.33337 13.3337 5.50004Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10.6673 2.83331C10.6673 3.65998 9.99398 4.33331 9.16732 4.33331H6.83398C6.42065 4.33331 6.0473 4.16664 5.77397 3.89331C5.50063 3.61998 5.33398 3.24665 5.33398 2.83331C5.33398 2.00665 6.00732 1.33331 6.83398 1.33331H9.16732C9.58065 1.33331 9.954 1.49998 10.2273 1.77332C10.5007 2.04665 10.6673 2.41998 10.6673 2.83331Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.33398 8.66669H8.00065" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.33398 11.3333H10.6673" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>



                                    </Link>

                                    <Link to='/reports' className={`text-base font-normal ${closeBar ? 'block' : 'hidden'}`}>Reports</Link>
                                </li>
                                </Link>

                               <Link to='/telematics'>
                               <li className={`flex items-center gap-2 p-2 rounded-lg ${activeLink === 'telematics' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('telematics')}>
                                    <Link to='/telematics'>

                                        <svg width="20" height="20" viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_54_11818)">
                                                <path d="M2.25047 16C1.64948 16 1.08654 15.767 0.659759 15.3402C0.219229 14.8997 -0.0148335 14.314 0.000728977 13.6912C0.0162914 13.0684 0.279291 12.4952 0.74129 12.0772L7.82359 5.66936C7.26378 4.14099 7.63687 2.39647 8.80165 1.23172C9.8608 0.172595 11.4212 -0.245092 12.8739 0.142001C13.0358 0.185126 13.1625 0.311438 13.206 0.473313C13.2495 0.635156 13.2032 0.807937 13.0847 0.926437L11.4099 2.60121L11.7414 4.25862L13.3988 4.59009L15.0736 2.91531C15.1921 2.79678 15.3648 2.75053 15.5267 2.79406C15.6886 2.83756 15.8149 2.96415 15.858 3.12612C16.245 4.57877 15.8274 6.13918 14.7683 7.19833C13.6035 8.36317 11.859 8.73617 10.3306 8.17639L3.92285 15.2587C3.50485 15.7207 2.93166 15.9837 2.30882 15.9993C2.28935 15.9998 2.26988 16 2.25047 16ZM11.7476 0.937593C10.9007 0.947718 10.0753 1.28397 9.46462 1.89469C8.49396 2.86537 8.23009 4.35362 8.80809 5.59805C8.89462 5.78436 8.84977 6.0053 8.69743 6.14315L1.37032 12.7724C1.10063 13.0164 0.947102 13.351 0.938008 13.7146C0.928915 14.0782 1.06554 14.4201 1.32273 14.6773C1.57991 14.9345 1.92173 15.072 2.28538 15.062C2.64897 15.0529 2.9836 14.8994 3.2276 14.6297L9.85686 7.30255C9.99471 7.15024 10.2156 7.10539 10.402 7.19189C11.6463 7.76992 13.1346 7.50602 14.1053 6.53536C14.7159 5.92471 15.0522 5.09924 15.0624 4.25234L13.8843 5.43046C13.7735 5.5413 13.6146 5.5894 13.4609 5.55868L11.251 5.11671C11.0655 5.07959 10.9204 4.93455 10.8833 4.74896L10.4414 2.53909C10.4106 2.3854 10.4587 2.2265 10.5696 2.11568L11.7476 0.937593Z" fill={`${user.night ? "black" : "white"}`} />
                                                <path d="M2.31836 14.1512C2.57724 14.1512 2.78711 13.9414 2.78711 13.6825C2.78711 13.4236 2.57724 13.2137 2.31836 13.2137C2.05948 13.2137 1.84961 13.4236 1.84961 13.6825C1.84961 13.9414 2.05948 14.1512 2.31836 14.1512Z"  />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_54_11818">
                                                    <rect width="16" height="16"  />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </Link>

                                    <Link to='/telematics' className={`text-base font-normal ${closeBar ? 'block' : 'hidden'}`}>Telematics</Link>
                                </li>
                               </Link>

                                <Link to='/inspaction'>
                                <li className={`flex items-center gap-2 p-2 rounded-lg ${activeLink === 'inspection_history' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('inspection_history')}>
                                    <Link to='/inspaction'>

                                        <svg width="20" height="20" viewBox="0 0 16 16" fill={`${user.night ? "none" : "white"}`} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 4.66665V11.3333C14 13.3333 13 14.6666 10.6667 14.6666H5.33333C3 14.6666 2 13.3333 2 11.3333V4.66665C2 2.66665 3 1.33331 5.33333 1.33331H10.6667C13 1.33331 14 2.66665 14 4.66665Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.66602 3V4.33333C9.66602 5.06667 10.266 5.66667 10.9993 5.66667H12.3327" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.33398 8.66669H8.00065" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.33398 11.3333H10.6673" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>





                                    </Link>

                                    <Link to='/inspaction' className={`text-base font-normal ${closeBar ? 'block' : 'hidden'}`}>Inspection history</Link>
                                </li>
                                </Link>

                            </div>

                        </ul>
                    </nav>
                </div>

                <footer>
                    <div className="dropdown">
                        
                

                        <div className="dropdown-header" onClick={toggleDropdown}>
                            <div className=" p-1 pl-2 pr-2 bg-blue-100 text-3xl font-bold rounded-lg" style={{ color: "#1996F0" }}>A</div>

                            <div className={`company-info flex justify-around ${closeBar ? 'block' : "hidden"}`}>
                                <div>
                                    <strong className='text-sm text-black'>Abrams Trucking LLC</strong>
                                    <p className='text-xs'>info@alliance-trucking.com</p>
                                </div>
                               
                                <svg className={`${isOpen ? 'rotate-180' : ''}`} width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.2797 6.00027L8.93306 10.3469C8.41973 10.8603 7.57973 10.8603 7.06639 10.3469L2.71973 6.00027" stroke="#1996F0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </div>

                        </div>

                        {isOpen && (
                            <div className="dropdown-menu">
                                <div 
                               
                                className={`menu-item flex items-center gap-3`} 
                                >
                                    <Link to='#'><img src={refresh2} alt="" /></Link>
                                    <Link className={`icon ${closeBar ? 'block' : 'hidden'}`} to='#'>Change company</Link>
                                </div>

                                <div 
                                
                                className={`menu-item flex items-center gap-3 `}>
                                    <Link to='/profile'> <img src={User} alt="" /></Link>

                                    <Link to='/profile' className={`icon ${closeBar ? 'block' : 'hidden'}`}>Profile</Link>
                                </div>

                                <div className="menu-item flex items-center gap-3">
                                    <Link to='/registrationform'><img src={briefcase} alt="" /></Link>
                                    <Link to='/registrationform' className={`icon ${closeBar ? 'block' : 'hidden'}`}>Company</Link>
                                </div>

                                <div className="menu-item logout flex items-center gap-3">
                                    <img src={logout} alt="" />
                                    <Link to='/' className={`icon ${closeBar ? 'block' : 'hidden'}`}>Log out</Link>
                                </div>
                            </div>
                        )}
                       
                    </div>
                </footer>
            </aside>


        </div>
    )
}

export default Sidebar