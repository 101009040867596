// Login.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';





const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const navigate = useNavigate()
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://671747adb910c6a6e027483d.mockapi.io/register/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            localStorage.setItem('forgotPasswordEmail',email)
            const data = await response.json();
            if (response.ok) {
                console.log(data);
              
                navigate("/emailverification")

            } else {
                alert('Login failed!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert("not corrent password or email")
        }
    };

    

    return (
        <header className='signin_header'>
            <div className="row_box">
                <div className="form_container flex items-center justify-center">
                    <form onSubmit={handleLogin} className='w-7/12 min-w-96 p-10'>
                        <div className='mb-8'>
                            <p className='text-base'>Password recovery.</p>
                            <h3 className='text-5xl font-semibold' style={{ color: "#1996F0" }}>Forgot your password?</h3>
                            <p className='text-start text-gray-400 mt-4'>Kindly enter the email address linked to this account and we will send you a code to enable you change your password.</p>
                        </div>

                        <div className='mb-5'>
                            <label htmlFor="useremail" className='text-base'>Email Address</label>

                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                id='useremail'
                                className='w-full rounded-xl p-2 mt-4'
                                maxLength={60}
                                minLength={4}
                                required
                            />
                        </div>

                      
        

                        <button type="submit" className='w-full text-white text-center p-2 rounded-xl' style={{ background: "#1996F0" }}>Send</button>
                        
                    </form>
                </div>
                <div className="img_container">

                </div>
            </div>
        </header>
    );
};

export default ForgotPassword;
