import React from 'react'
import Sidebar from '../Dashboard/Sidebar/Sidebar'
import Navbar from '../Dashboard/DashboarNavbar/DashboardNavbar'
import Resources from './Resources'

function ResourcesLayout() {
  return (
    <div className='dashboard pl-2 pr-2'>
        <Sidebar/>
        <main className='main-content'>
            <div className='main-content'>
            <Navbar/>
            </div>
            <Resources/>
        </main>
    </div>
  )
}

export default ResourcesLayout