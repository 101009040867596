import React,{useState} from 'react'
import ReportBox from './CreateReport';
import ReportHistory from './ReportHistory';

function Reports() {
    const [reports,setReports] = useState('reports');



    const changeReports = (value) =>{
        setReports(value);
        
    }


    return (
    <div className='p-3'>
        <div className='main-content'>
            
        <div className=' pb-3 w-full h-[80px] flex items-center border-b-[2px]'>
            <div>
            <h1 className='text-[30px] font-semibold' style={{color:'#1996F0'}}>Reports</h1>
            <div className='flex items-center gap-5 font-semibold'>
                
                <p className='cursor-pointer' onClick={() => changeReports('reports')} style={{color: reports == 'reports' ? '#1996F0':'black'}}>Create report</p>          
                <p className='cursor-pointer' onClick={() => changeReports('report_history')} style={{color: reports == 'report_history' ? '#1996F0':'black'}}>Report history</p>
            </div>
            </div>
        </div>
        {
            reports ==='reports' ?
        <ReportBox/> :
        <ReportHistory/>  
        }

        </div>
    </div>
  )
}

export default Reports