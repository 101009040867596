import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../DashboarNavbar/DashboardNavbar';
import { Link } from 'react-router-dom';


function Telematics() {
  const [logs, setLogs] = useState([]);
  const itemsPerPage = 5; // har bir sahifada ko'rsatish uchun elementlar soni
  const [page, setPage] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://xertzdigital.net/telematics.json');
        setLogs(response.data);
      } catch (error) {
        console.error("Ma'lumotlarni yuklashda xatolik:", error);
      }
    };
    fetchData();
  }, []);

  const totalPages = Math.ceil(logs.length / itemsPerPage);

  const getPageData = () => {
    const start = page * itemsPerPage;
    return logs.slice(start, start + itemsPerPage);
  };




  return (
    <div className='dashboard pl-2 pr-2'>

      <Sidebar />

      <main className='main-content'>

        <div className='main-content'>
        <Navbar />
        </div>

        <div className='main-content'>
          
        <div className='p-3'>
          <div className='flex items-center justify-between h-[80px] border-b-[2px] mb-3'>
            <h1 className='font-semibold text-[30px]' style={{ color: '#1996F0' }}>Telematics</h1>
            <div className='flex items-center gap-4 '>
              <div className='flex items-center gap-3 border-[2px] border-gray-300 p-1 rounded-lg'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.58268 17.5C13.9549 17.5 17.4993 13.9556 17.4993 9.58335C17.4993 5.2111 13.9549 1.66669 9.58268 1.66669C5.21043 1.66669 1.66602 5.2111 1.66602 9.58335C1.66602 13.9556 5.21043 17.5 9.58268 17.5Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M18.3327 18.3334L16.666 16.6667" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <input type="text" placeholder='Search' className='border-none bg-transparent focus:outline-none' />
              </div>
            </div>
          </div>
          
          <Carousel showThumbs={false} showStatus={false}>
            {Array.from({ length: totalPages }, (_, i) => (
              <div key={i} className='w-full bg-white h-full'>



                {/* ------------------------------------------------------------------------------------------------------------------ */}

                <div className='w-full border-2 rounded-lg p-4'>


                  <table style={{ height: '60vh', width: '100%' }}>
                    <thead>
                      <tr className='font-normal text-sm border-b-2 pb-3' style={{ color: "#808080" }}>

                        <th>DRIVERS</th>
                        <th>MAKE/MODEL</th>
                        <th>VIN</th>
                        <th>TRUCK NUMBER</th>
                        <th>TELEMATICS</th>


                      </tr>
                    </thead>
                    <tbody>

                      {getPageData().map((log, index) => (

                        <tr key={index} className='border-b-[2px]'>

                          <td>{log.drivers}</td>
                          <td>{log.make_model}</td>
                          <td>{log.vin}</td>
                          <td>{log.truck_number}</td>

                          <td className=' h-full flex items-center justify-center'>
                            <Link to={`/telematics/${log.id}`}>
                              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="35" height="35" rx="4" fill="#E8F3FF" />
                                <g clip-path="url(#clip0_275_10980)">
                                  <path d="M10.8131 28C10.0618 28 9.35817 27.7088 8.8247 27.1753C8.27404 26.6246 7.98146 25.8925 8.00091 25.114C8.02036 24.3355 8.34911 23.619 8.92661 23.0965L17.7795 15.0867C17.0797 13.1762 17.5461 10.9956 19.0021 9.53965C20.326 8.21574 22.2765 7.69364 24.0924 8.1775C24.2948 8.23141 24.4531 8.3893 24.5074 8.59164C24.5618 8.79395 24.504 9.00992 24.3559 9.15805L22.2624 11.2515L22.6767 13.3233L24.7485 13.7376L26.842 11.6441C26.9901 11.496 27.2059 11.4382 27.4084 11.4926C27.6107 11.5469 27.7686 11.7052 27.8226 11.9077C28.3063 13.7235 27.7843 15.674 26.4604 16.9979C25.0044 18.454 22.8238 18.9202 20.9133 18.2205L12.9036 27.0734C12.3811 27.6509 11.6646 27.9797 10.886 27.9991C10.8617 27.9997 10.8374 28 10.8131 28ZM22.6845 9.17199C21.6258 9.18465 20.5942 9.60496 19.8308 10.3684C18.6175 11.5817 18.2876 13.442 19.0101 14.9976C19.1183 15.2305 19.0622 15.5066 18.8718 15.6789L9.7129 23.9655C9.37579 24.2705 9.18388 24.6888 9.17251 25.1433C9.16114 25.5978 9.33192 26.0251 9.65341 26.3466C9.97489 26.6681 10.4022 26.84 10.8567 26.8275C11.3112 26.8161 11.7295 26.6242 12.0345 26.2871L20.3211 17.1282C20.4934 16.9378 20.7695 16.8817 21.0024 16.9899C22.5579 17.7124 24.4182 17.3825 25.6316 16.1692C26.3949 15.4059 26.8152 14.3741 26.828 13.3154L25.3554 14.7881C25.2169 14.9266 25.0183 14.9867 24.8262 14.9483L22.0638 14.3959C21.8319 14.3495 21.6505 14.1682 21.6042 13.9362L21.0517 11.1739C21.0133 10.9818 21.0734 10.7831 21.2119 10.6446L22.6845 9.17199Z" fill="#1996F0" />
                                  <path d="M10.8984 25.6891C11.222 25.6891 11.4844 25.4268 11.4844 25.1031C11.4844 24.7795 11.222 24.5172 10.8984 24.5172C10.5748 24.5172 10.3125 24.7795 10.3125 25.1031C10.3125 25.4268 10.5748 25.6891 10.8984 25.6891Z" fill="#1996F0" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_275_10980">
                                    <rect width="20" height="20" fill="white" transform="translate(8 8)" />
                                  </clipPath>
                                </defs>
                              </svg>


                            </Link>
                          </td>

                        </tr>

                      ))}

                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        </div>

      </main>


    </div>
  )
}

export default Telematics;