import { color } from 'd3';
import React, { useState } from 'react';


const reports = [
    { title: 'IFTA', link: '/path/to/ifta-report.pdf' },
    { title: 'IFTA by Vehicles', link: '/path/to/ifta-by-vehicles.pdf' },
    { title: 'Trip report', link: '/path/to/trip-report.pdf' },
    { title: 'Vehicle maintenance report', link: '/path/to/vehicle-maintenance.pdf' },
    { title: 'ELD report', link: '/path/to/eld-report.pdf' },
    { title: 'Speeding report', link: '/path/to/speeding-report.pdf' }
];

const ReportBox = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [type, setType] = useState('');

    return (
        <div className='w-full flex justify-between gap-1 flex-wrap mt-5'>

            {
                reports.map((item, index) => {
                    return (
                        <div key={index} className='lg:w-[48%] 2xl:w-[33%] border-[2px] border-gray-200 rounded-lg p-3 mb-4 '>
                            <h3 className='text-[20px] font-semibold text-black mb-3'>{item.title}</h3>

                            <div className='flex items-start justify-between flex-wrap'>

                                <div className='flex items-start gap-1'>
                                    <div>
                                        <input
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className='border-[2px] border-gray-400 p-1 rounded-md text-gray-500 focus:outline-none'
                                            disabled
                                        />
                                    </div>

                                    <div style={{ marginBottom: '10px' }}>
                                        <input
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            className='border-[2px] border-gray-400 p-1 rounded-md text-gray-500 focus:outline-none'
                                            disabled
                                        />
                                    </div>
                                </div>

                                <a href={item.link} download>
                                    <button className='flex items-center gap-2 font-semibold rounded-lg border-none p-2 mb-2' style={{background:'#E8F3FF',color:"#1996F0"}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.76758 9.73334L9.90091 11.8667L12.0342 9.73334" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.90039 3.33331V11.8083" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.6673 10.15C16.6673 13.8334 14.1673 16.8167 10.0007 16.8167C5.83398 16.8167 3.33398 13.8334 3.33398 10.15" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                        <p>Download</p>
                                    </button>
                                </a>
                            </div>


                            <div>
                                
                                <select
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    disabled
                                    style={{background:"#F8F8FA"}}
                                    className='w-full focus:outline-none font-semibold text-gray-400'
                                >
                                    <option value="">Select type</option>
                                    <option value="summary">Summary</option>
                                    <option value="detailed">Detailed</option>
                                </select>
                            </div>

                        </div>
                    )
                })
            }
        </div>
    );
};

export default ReportBox;