import React, { createContext, useState } from 'react'
import './App.css';
import SignIn from './Components/Registration/Signin/SingIn';
import { Route, Routes } from 'react-router-dom';
import SignUp from './Components/Registration/Signup/SignUp';
import ForgotPassword from './Components/Registration/ForgotPassword/ForgotPassword';
import SignUpVerification from './Components/Registration/SignUpVerification/signUpVerification';
import PasswordSet from './Components/Registration/PasswordSet/PasswordSet';
import EmailVerification from './Components/Registration/EmailVerification/EmailVerification';
import PasswordReset from './Components/Registration/PasswordReset/PasswordReset';
import Layout from './Components/Dashboard/DashboardLayout/DashboardLoyaut';
import DashboardMap from './Components/Dashboard/DashboardMap/DashboardMap';
import RegistrationForm from './Components/Dashboard/DashboardCreateAcc/DashboardNewAcc';
import ProfileEdit from './Components/Dashboard/Profile/Profile';
import LogCarousel from './Components/Dashboard/Logs/Logs';
import LogsLayout from './Components/Dashboard/Logs/LogsLayout';
import DriverDoc from './Components/Dashboard/DriverDog/DriverDoc';
import TrackingLayout from './Components/Dashboard/Tracking/TrackingLayout';
import DriverLayout from './Components/Dashboard/Driver/DriverLayout';
import AddDriver from './Components/Dashboard/Driver/AddDriver';
import VehiclesLayout from './Components/Dashboard/Vehicles/VehiclesLayout';
import CreateVehicleLayout from './Components/Dashboard/Vehicles/CreateVehicleLayout';
import ResourcesLayout from './Components/Resources/ResourcesLayout';
import ReportsLayout from './Components/Dashboard/Reports/ReportsLayout';
import Telematics from './Components/Dashboard/Telematics/Telematics';
import Diagnostics from './Components/Dashboard/Telematics/Diagnostics';
import Inspaction from './Components/Dashboard/Inspaction/Inspaction';
import TripPannel from './Components/Dashboard/TripPannel/TripPsnnel';
import TrackingMap from './Components/Dashboard/TrackingMap/TrackingMap';
import useTokenRefresher from './Api/TokenRefresher';
import AdminCompanies from './Components/Registration/AdminCompanies/AdminCompanies';
import EditDriver from './Components/Dashboard/Driver/editDriver';
import EditVehicles from './Components/Dashboard/Vehicles/editVehicle';


export const UserContext = createContext();

function App() {
  const [night,setNight] = useState(true)
  useTokenRefresher();

  const NightMode = () =>{
  
    setNight(night != true)
   
  }

  return (
    <div className="App" style={{background:night ? '#fff' : "#1E2D4C",color:night ? '' : "#fff",transition:'all 0.3s ease'}}>
        <UserContext.Provider value={{NightMode,night}} >
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path='signup' element={<SignUp />} />
        <Route path='forgotpassword' element={<ForgotPassword />} />
        <Route path='signupverification' element={<SignUpVerification />} />
        <Route path='passwordset' element={<PasswordSet />} />
        <Route path='emailverification' element={<EmailVerification />} />
        <Route path='passwordreset' element={<PasswordReset />} />
        <Route path='layout' element={<Layout/>} />
        <Route path='dashboardmap' element={<DashboardMap />} />
        <Route path='registrationform' element={<RegistrationForm />} />
        <Route path='profile' element={<ProfileEdit/>} />
        <Route path='logs' element={<LogsLayout/>} />
        <Route path='logs/:id' element={<DriverDoc/>} />
        <Route path='tracking' element={<TrackingLayout/>} />
        <Route path='tracking/:id' element={<TrackingMap/>} />
        <Route path='driver' element={<DriverLayout/>} />
        <Route path='adddriver' element={<AddDriver/>} />
        <Route path='driver/:id' element={<EditDriver/>} />
        <Route path='vehicles' element={<VehiclesLayout/>} />
        <Route path='vehicles/:id' element={<EditVehicles/>} />
        <Route path='createvehicle' element={<CreateVehicleLayout/>} />
        <Route path='resources' element={<ResourcesLayout/>} />
        <Route path='reports' element={<ReportsLayout/>} />
        <Route path='telematics' element={<Telematics/>} />
        <Route path='telematics/:id' element={<Diagnostics/>} />
        <Route path='logs/:id/telematics' element={<Diagnostics/>} />
        <Route path='inspaction' element={<Inspaction/>} />
        <Route path='logs/:id/trippanel' element={<TripPannel/>} />
        <Route path='admincompanies' element={<AdminCompanies/>} />
      </Routes>
        </UserContext.Provider>

    </div>
  );
}

export default App;
