import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import './driver.css'
import { Link, useNavigate } from 'react-router-dom';
import Android from './android.png'
import iPhone from './apple.png'
import { UserContext } from '../../../App';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Button, NavItem } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';





function DriverDoc() {
    const [logs, setLogs] = useState([]);
    const [selected, setSelected] = useState("all");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);
    const [searchDriver, setSearchDriver] = useState('')
    const [filter, setFilter] = useState("")
    const navigat = useNavigate()


    const styles = {
        container: {
            display: "flex",
            border: "1px solid #ddd",
            borderRadius: "8px",
            overflow: "hidden",
            width: "fit-content",
        },
        button: {
            border: "none",
            background: "none",
            color: "#888",
            cursor: "pointer",
        },
        activeButton: {
            border: "none",
            backgroundColor: "#1996F0", // Ko'k rang
            color: "#fff",
            cursor: "pointer",
        },
    };

    let companyId = localStorage.getItem("companyId");

    const accessToken = localStorage.getItem('accessToken');



    const fetchData = async (page) => {

        try {
            const response = await axios.get(`https://superservice.uz/api/v1/accounts/company/drivers/${companyId}/?is_active=${filter}&full_name=${searchDriver}&page=${page}`);
            setLogs(response.data.results);
            setTotalPages(response.data.total_pages);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    useEffect(() => {
        fetchData(currentPage);
    }, [filter, searchDriver, currentPage]);


    const filterDriver = (active, css) => {
        setFilter(active);
        setSelected(css);
    };

    const openDeleteDialog = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`https://superservice.uz/api/v1/accounts/drivers/${selectedId}/delete/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            console.log("Driver deleted successfully");
            setOpen(false); // Modalni yopish
            fetchData(currentPage); // Ro'yxatni yangilash
        } catch (error) {
            console.error("Delete error:", error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };


    const user = useContext(UserContext)

    const handleChange = (e, p) => {

        setCurrentPage(p);
    }

    return (
        <div className='p-3'>

            <div className='main-content'>
                <div className='w-full  h-full' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>


                    <div className=' w-full flex items-center justify-between mb-3' >
                        <h1 className='text-[30px] font-bold' style={{ color: '#1996F0' }}>Drivers</h1>
                        <div className='flex items-center gap-5'>

                            <div className='flex items-center gap-2 border-[1px] border-gray-400 rounded-lg p-1'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.58366 17.5C13.9559 17.5 17.5003 13.9556 17.5003 9.58335C17.5003 5.2111 13.9559 1.66669 9.58366 1.66669C5.2114 1.66669 1.66699 5.2111 1.66699 9.58335C1.66699 13.9556 5.2114 17.5 9.58366 17.5Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.3337 18.3334L16.667 16.6667" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <input type="text" placeholder='Search' maxLength={100} className='focus:outline-none placeholder:text-gray-500 font-semibold bg-transparent' onChange={(e) => setSearchDriver(e.target.value)} />
                            </div>

                            <div style={styles.container} className='pt-0 flex gap-3'>
                                <button
                                    className='p-1 pl-3 pr-3 rounded-lg'
                                    style={selected === "active" ? styles.activeButton : styles.button}
                                    onClick={() => filterDriver(true, 'active')}
                                >
                                    Active
                                </button>
                                <button
                                    className='p-1 pl-3 pr-3 rounded-lg'
                                    style={selected === "all" ? styles.activeButton : styles.button}
                                    onClick={() => filterDriver("", 'all')}
                                >
                                    All
                                </button>
                                <button
                                    className='p-1 pl-3 pr-3 rounded-lg'
                                    style={selected === "not_active" ? styles.activeButton : styles.button}
                                    onClick={() => filterDriver(false, 'not_active')}
                                >
                                    Not active
                                </button>
                            </div>

                            <button
                                onClick={() => navigat('/adddriver')}
                                className='pl-4 pr-4 p-1 rounded-md flex items-center justify-between gap-2 border-none'
                                style={{ background: "#E8F3FF", color: "#1996F0" }}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99967 10C12.3009 10 14.1663 8.13454 14.1663 5.83335C14.1663 3.53217 12.3009 1.66669 9.99967 1.66669C7.69849 1.66669 5.83301 3.53217 5.83301 5.83335C5.83301 8.13454 7.69849 10 9.99967 10Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2.8418 18.3333C2.8418 15.1083 6.05013 12.5 10.0001 12.5C10.8001 12.5 11.5751 12.6083 12.3001 12.8083" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.3337 15C18.3337 15.2667 18.3003 15.525 18.2337 15.775C18.1587 16.1083 18.0253 16.4334 17.8503 16.7167C17.2753 17.6834 16.217 18.3334 15.0003 18.3334C14.142 18.3334 13.367 18.0083 12.7837 17.475C12.5337 17.2583 12.317 17 12.1503 16.7167C11.842 16.2167 11.667 15.625 11.667 15C11.667 14.1 12.0253 13.275 12.6087 12.675C13.217 12.05 14.067 11.6667 15.0003 11.6667C15.9837 11.6667 16.8753 12.0917 17.4753 12.775C18.0087 13.3667 18.3337 14.15 18.3337 15Z" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.2421 14.9833H13.7588" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15 13.7667V16.2583" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <p>Add driver</p>
                            </button>

                            <button
                                className='pl-4 pr-4 p-1 rounded-md flex items-center justify-between gap-2 border-none'
                                style={{ background: "#E8F3FF", color: "#1996F0" }}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_41_6623)">
                                        <path d="M11.0238 0.768342C10.8813 0.650842 10.6925 0.602092 10.51 0.635842L0.51 2.51084C0.21375 2.56584 0 2.82334 0 3.12459V16.8746C0 17.1746 0.21375 17.4333 0.51 17.4883L10.51 19.3633C10.5475 19.3708 10.5875 19.3746 10.625 19.3746C10.7688 19.3746 10.9113 19.3246 11.0238 19.2308C11.1675 19.1121 11.25 18.9346 11.25 18.7496V1.24959C11.25 1.06334 11.1675 0.887092 11.0238 0.768342ZM10 17.9958L1.25 16.3558V3.64334L10 2.00334V17.9958Z" fill="#1996F0" />
                                        <path d="M19.375 3.12463H10.625C10.28 3.12463 10 3.40463 10 3.74963C10 4.09463 10.28 4.37463 10.625 4.37463H18.75V15.6246H10.625C10.28 15.6246 10 15.9046 10 16.2496C10 16.5946 10.28 16.8746 10.625 16.8746H19.375C19.72 16.8746 20 16.5946 20 16.2496V3.74963C20 3.40463 19.72 3.12463 19.375 3.12463Z" fill="#1996F0" />
                                        <path d="M13.125 5.62463H10.625C10.28 5.62463 10 5.90463 10 6.24963C10 6.59463 10.28 6.87463 10.625 6.87463H13.125C13.47 6.87463 13.75 6.59463 13.75 6.24963C13.75 5.90463 13.47 5.62463 13.125 5.62463Z" fill="#1996F0" />
                                        <path d="M13.125 8.12463H10.625C10.28 8.12463 10 8.40463 10 8.74963C10 9.09463 10.28 9.37463 10.625 9.37463H13.125C13.47 9.37463 13.75 9.09463 13.75 8.74963C13.75 8.40463 13.47 8.12463 13.125 8.12463Z" fill="#1996F0" />
                                        <path d="M13.125 10.6246H10.625C10.28 10.6246 10 10.9046 10 11.2496C10 11.5946 10.28 11.8746 10.625 11.8746H13.125C13.47 11.8746 13.75 11.5946 13.75 11.2496C13.75 10.9046 13.47 10.6246 13.125 10.6246Z" fill="#1996F0" />
                                        <path d="M13.125 13.1246H10.625C10.28 13.1246 10 13.4046 10 13.7496C10 14.0946 10.28 14.3746 10.625 14.3746H13.125C13.47 14.3746 13.75 14.0946 13.75 13.7496C13.75 13.4046 13.47 13.1246 13.125 13.1246Z" fill="#1996F0" />
                                        <path d="M16.875 5.62463H15.625C15.28 5.62463 15 5.90463 15 6.24963C15 6.59463 15.28 6.87463 15.625 6.87463H16.875C17.22 6.87463 17.5 6.59463 17.5 6.24963C17.5 5.90463 17.22 5.62463 16.875 5.62463Z" fill="#1996F0" />
                                        <path d="M16.875 8.12463H15.625C15.28 8.12463 15 8.40463 15 8.74963C15 9.09463 15.28 9.37463 15.625 9.37463H16.875C17.22 9.37463 17.5 9.09463 17.5 8.74963C17.5 8.40463 17.22 8.12463 16.875 8.12463Z" fill="#1996F0" />
                                        <path d="M16.875 10.6246H15.625C15.28 10.6246 15 10.9046 15 11.2496C15 11.5946 15.28 11.8746 15.625 11.8746H16.875C17.22 11.8746 17.5 11.5946 17.5 11.2496C17.5 10.9046 17.22 10.6246 16.875 10.6246Z" fill="#1996F0" />
                                        <path d="M16.875 13.1246H15.625C15.28 13.1246 15 13.4046 15 13.7496C15 14.0946 15.28 14.3746 15.625 14.3746H16.875C17.22 14.3746 17.5 14.0946 17.5 13.7496C17.5 13.4046 17.22 13.1246 16.875 13.1246Z" fill="#1996F0" />
                                        <path d="M8.59608 12.0883L4.22108 7.08833C3.99108 6.82708 3.59733 6.80208 3.33858 7.02958C3.07858 7.25708 3.05233 7.65208 3.27983 7.91083L7.65483 12.9108C7.77858 13.0521 7.95108 13.1246 8.12483 13.1246C8.27108 13.1246 8.41733 13.0733 8.53733 12.9696C8.79733 12.7421 8.82358 12.3483 8.59608 12.0883Z" fill="#1996F0" />
                                        <path d="M8.50877 6.38212C8.23627 6.16837 7.84377 6.21962 7.63127 6.49087L3.25627 12.1159C3.04502 12.3884 3.09377 12.7821 3.36627 12.9934C3.48127 13.0821 3.61627 13.1246 3.75002 13.1246C3.93502 13.1246 4.12002 13.0421 4.24252 12.8846L8.61752 7.25962C8.83002 6.98587 8.78127 6.59337 8.50877 6.38212Z" fill="#1996F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_41_6623">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <p>Upload Excel file</p>
                            </button>

                            <button>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                                    <path d="M20.8901 11.08C20.0201 10.82 19.0601 10.65 18.0001 10.65C13.2101 10.65 9.33008 14.53 9.33008 19.32C9.33008 24.12 13.2101 28 18.0001 28C22.7901 28 26.6701 24.12 26.6701 19.33C26.6701 17.55 26.1301 15.89 25.2101 14.51" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22.1302 11.32L19.2402 8" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22.1298 11.3199L18.7598 13.7799" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </button>

                        </div>
                    </div>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to delete the driver?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>NO</Button>
                            <Button onClick={handleDelete} autoFocus>
                                YES
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <div className=' h-[73vh] border-2 rounded-lg p-4 flex justify-between flex-col driver_doc'>


                        <table className='w-full'>


                            <thead className=''>
                                <tr className='font-normal text-sm w-full' style={{ color: "#808080" }}>
                                    <th>FIRST NAME</th>
                                    <th>LAST NAME</th>
                                    <th>VEHICLE ID</th>
                                    <th>USER NAME</th>
                                    <th>CO-DRIVER</th>
                                    <th>NOTES</th>
                                    <th>APP VERSION</th>
                                    <th>ACTIVATED</th>
                                    <th>TERMINATED</th>
                                    <th>ELD CONNECTION</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>



                            <tbody >


                                {
                                    logs.map((log) => (
                                        <tr key={log.id} className='border-b-[1px] border-t-[1px] text-center h-[50px]' >
                                            <td>{log.first_name}</td>
                                            <td>{log.last_name}</td>
                                            <td>{log.vehicle_id != null ? log.vehicle_id : "not available"}</td>
                                            <td>{log.credential}</td>
                                            <td>{log.co_driver.lenght > 1 ? log.co_driver : "not available"}</td>
                                            <td>{log.notes}</td>
                                            <td className='felx items-center gap-5'>
                                                <img src={log.app_type == 'android' ? Android : log.app_type == 'iphone' ? iPhone : ''} alt="" style={{ width: '20px' }} />
                                                {log.app_version != null ? log.app_version : 'not available'}
                                            </td>
                                            <td>{log.activated_date != null ? log.activated_date : "not available"}</td>
                                            <td>{log.terminated_date != null ? log.terminated_date : "not available"}</td>
                                            <td>
                                                <button
                                                    className='p-1 rounded-lg text-white'
                                                    style={{
                                                        background: log.eld_connection ? '#5CB800' : "#ED3237"
                                                    }}
                                                >
                                                    {log.eld_connection ? 'Active' : "Unactive"}
                                                </button>
                                            </td>
                                            <td className='cursor-pointer h-[50px] flex items-center gap-3'>
                                                <Link to={`/driver/${log.id}`}>
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="30" height="30" rx="4" fill="#FFEECE" />
                                                        <path d="M15.9631 8.6727L9.80558 15.1902C9.57308 15.4377 9.34808 15.9252 9.30308 16.2627L9.02558 18.6927C8.92808 19.5702 9.55808 20.1702 10.4281 20.0202L12.8431 19.6077C13.1806 19.5477 13.6531 19.3002 13.8856 19.0452L20.0431 12.5277C21.1081 11.4027 21.5881 10.1202 19.9306 8.5527C18.2806 7.0002 17.0281 7.5477 15.9631 8.6727Z" stroke="#FFA800" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M14.9346 9.76013C15.2571 11.8301 16.9371 13.4126 19.0221 13.6226" stroke="#FFA800" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8.26758 22.4727H21.7676" stroke="#FFA800" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </Link>
                                                <button onClick={() => openDeleteDialog(log.id)}>
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="30" height="30" rx="4" fill="#FFE2E3" />
                                                        <path d="M11.6279 18.5613L18.699 11.4902" stroke="#ED3237" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M18.699 18.5613L11.6279 11.4902" stroke="#ED3237" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button>

                                            </td>
                                        </tr>
                                    ))
                                }


                            </tbody>


                        </table>


                        <div className='flex items-center justify-center'>
                            <Pagination count={totalPages} shape="rounded" color='primary' onChange={handleChange} />
                        </div>



                    </div>

                </div>

            </div>


        </div>
    )
}

export default DriverDoc