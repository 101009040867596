import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../DashboarNavbar/DashboardNavbar'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import offIcon from './icons/off.png'
import sbIcon from './icons/sb.png'
import drIcon from './icons/dr.png'
import onIcon from './icons/on.png'
import startIcon from './icons/start.png'
import endIcon from './icons/end.png'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

delete L.Icon.Default.prototype._getIconUrl;



const icons = {
    off: new L.Icon({ iconUrl: offIcon, iconSize: [15, 21], iconAnchor: [12, 41] }),
    on: new L.Icon({ iconUrl: onIcon, iconSize: [45, 50], iconAnchor: [12, 41] }),
    sb: new L.Icon({ iconUrl: sbIcon, iconSize: [15, 21], iconAnchor: [12, 41] }),
    dr: new L.Icon({ iconUrl: drIcon, iconSize: [45, 50], iconAnchor: [12, 41] }),
    start: new L.Icon({ iconUrl: startIcon, iconSize: [30, 40], iconAnchor: [15, 45] }),
    end: new L.Icon({ iconUrl: endIcon, iconSize: [30, 40], iconAnchor: [15, 45] }),
};
function TrackingMap() {
    const params = useParams();
    const [markers, setMarkers] = useState([]);
    const [driver, setDriver] = useState([]);

    useEffect(() => {
        const fetchMarker = async () => {
            try {
                const response = await axios.get(`https://superservice.uz/api/v1/main/dashboard/tracking-detail/${params.id}/`);
                setMarkers(response.data.activities);
                setDriver(response.data.driver);
                console.log(response);
            } catch (error) {
                console.error('Xatolik:', error);
            }
        };

        fetchMarker();
    }, [params.id]);

    return (
        <div className="dashboard pl-2 pr-2">

            <Sidebar />

            <main className="main-content">
                <Navbar />

                <div className='general_map p-3'>

                    <div className=' h-[60px] flex items-center justify-between border-b-[2px] border-gray-300 mb-3 pb-[10px]'>

                        <div className='flex items-center gap-[30px]'>
                            <div className='border-r-[2px] pr-[10px] border-gray-300'>
                                <small className='text-[16px]' style={{ color: "#808080" }}>Driver</small>
                                <p className='text-[20px] font-[600]'>{driver.first_name} {driver.last_name}</p>
                            </div>
                            <div className='border-r-[2px] pr-[10px] border-gray-300'>
                                <small className='text-[16px]' style={{ color: "#808080" }}>Driver Id</small>
                                <p className='text-[20px] font-[600]'>{driver.id}</p>
                            </div>
                            <div className='border-r-[2px] pr-[10px] border-gray-300'>
                                <small className='text-[16px]' style={{ color: "#808080" }}>Phone number</small>
                                <p className='text-[20px] font-[600]'>{driver.phone}</p>
                            </div>
                        </div>

                        <div className='flex items-center gap-[30px]'>
                            <div
                                className='flex items-center gap-[10px] border-[1px] rounded-[8px] border-gray-300 p-1 pl-3 pr-3'
                                style={{ background: "#F8F8FA", color: "#808080" }}
                            >
                                <p>{driver.date}</p>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.66699 1.66663V4.16663" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13.333 1.66663V4.16663" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2.91699 7.57495H17.0837" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17.5 7.08329V14.1666C17.5 16.6666 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6666 2.5 14.1666V7.08329C2.5 4.58329 3.75 2.91663 6.66667 2.91663H13.3333C16.25 2.91663 17.5 4.58329 17.5 7.08329Z" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13.0791 11.4167H13.0866" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13.0791 13.9167H13.0866" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9.99607 11.4167H10.0036" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9.99607 13.9167H10.0036" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.91209 11.4167H6.91957" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.91209 13.9167H6.91957" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </div>

                            <div className='flex items-center'>
                                <button className='p-1' style={{ background: "#E8F3FF" }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4995 16.6L7.06621 11.1667C6.42454 10.525 6.42454 9.47503 7.06621 8.83336L12.4995 3.40002" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                </button>
                                <button className='p-1' style={{ background: "#E8F3FF" }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.50046 3.39997L12.9338 8.83331C13.5755 9.47498 13.5755 10.525 12.9338 11.1666L7.50046 16.6" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>


                                </button>
                            </div>

                            <button>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                                    <path d="M20.8901 11.08C20.0201 10.82 19.0601 10.65 18.0001 10.65C13.2101 10.65 9.33008 14.53 9.33008 19.32C9.33008 24.12 13.2101 28 18.0001 28C22.7901 28 26.6701 24.12 26.6701 19.33C26.6701 17.55 26.1301 15.89 25.2101 14.51" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22.1302 11.32L19.2402 8" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22.1298 11.3199L18.7598 13.7799" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </button>

                        </div>
                    </div>

                    <MapContainer
                        center={[41.2995, 69.2401]} // Default Toshkent
                        zoom={6}
                        style={{ height: "500px", width: "100%" }}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />

                        {
                            markers != undefined ? <MarkerClusterGroup>
                                {
                                    markers.length > 0 && markers.map((marker, index) => {
                                        // Boshlang'ich va oxirgi nuqtalarni aniqlash
                                        const isStart = index === 0;
                                        const isEnd = index === markers.length - 1;
            
                                        // Marker uchun icon tanlash
                                        let icon;
                                        if (isStart) {
                                            icon = icons.start;
                                        } else if (isEnd) {
                                            icon = icons.end;
                                        } else {
                                            icon = icons[marker.activity] || icons.off; // Default 'off' marker
                                        }
            
                                        return (
                                            <Marker
                                                key={index}
                                                position={[marker.latitude, marker.longitude]}
                                                icon={icon}
                                            >
                                                <Popup>
                                                    <div>
                                                        <p><strong>Location:</strong> {marker.location}</p>
                                                        <p><strong>Activity:</strong> {marker.activity}</p>
                                                    </div>
                                                </Popup>
                                            </Marker>
                                        );
                                    })
                                }
                            </MarkerClusterGroup> : <></>
                        }
                    </MapContainer>


                </div>

            </main>

        </div>
    )
}

export default TrackingMap