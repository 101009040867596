import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../DashboarNavbar/DashboardNavbar'
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useParams } from 'react-router-dom';
import axios from 'axios';


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function TripPannel() {
  const params = useParams();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [markers, setMarkers] = useState([]);
  const API_KEY = "15dae500ce3a4536bb6278afeb26e3e9"; // OpenCage API kaliti

  const handleRunTrip = async () => {
    const getCoordinates = async (location) => {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${location}&key=${API_KEY}`
      );
      const { lat, lng } = response.data.results[0].geometry;
      return [lat, lng];
    };

    try {
      const fromCoords = await getCoordinates(from);
      const toCoords = await getCoordinates(to);
      setMarkers([
        { position: fromCoords, name: from },
        { position: toCoords, name: to },
      ]);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  return (
    <div className="dashboard pl-2 pr-2">

      <Sidebar />

      <main className="main-content">
        <Navbar />

        <div className='general_map p-3'>

          <div>

            <div className='h-[60px] flex items-center justify-between'>
              <h1 className='text-[24px] font-semibold' style={{ color: '#1996F0' }}>Trip Panel</h1>
              <div className='flex items-center gap-5'>
                <button className='p-1 pl-[8px] pr-[8px] rounded-lg flex items-center gap-2' style={{ background: "#E8F3FF", color: "#1996F0" }}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.167 11.1666V13.6666C14.167 16.9999 12.8337 18.3333 9.50033 18.3333H6.33366C3.00033 18.3333 1.66699 16.9999 1.66699 13.6666V10.4999C1.66699 7.16659 3.00033 5.83325 6.33366 5.83325H8.83366" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14.1663 11.1666H11.4997C9.49967 11.1666 8.83301 10.4999 8.83301 8.49992V5.83325L14.1663 11.1666Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.66699 1.66675H13.0003" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5.83301 4.16675C5.83301 2.78341 6.94967 1.66675 8.33301 1.66675H10.5163" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.3329 6.66675V11.8251C18.3329 13.1167 17.2829 14.1667 15.9912 14.1667" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.333 6.66675H15.833C13.958 6.66675 13.333 6.04175 13.333 4.16675V1.66675L18.333 6.66675Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Copy last location
                </button>
                <button
                  className='p-1 pl-[8px] pr-[8px] rounded-lg '
                  style={{ background: "#E8F3FF", color: "#1996F0" }}
                >Satellite</button>
              </div>
            </div>

            <div className='mb-3'>
             
                <div className='flex items-end gap-[15px]'>
                  <div className='w-[300px]'>
                    <label htmlFor="from" className='font-semibold'>From</label> <br />
                    <input
                      id='from'
                      type="text"
                      className='w-full p-1 border-[1px] rounded-md border-gray-300 bg-gray-100 focus:outline-none'
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                    />
                  </div>

                  <div className='w-[300px]'>
                    <label htmlFor="from" className='font-semibold'>To</label> <br />
                    <input
                      id='from'
                      type="text"
                      className='w-full p-1 border-[1px] rounded-md border-gray-300 bg-gray-100 focus:outline-none'
                      value={to}
                      onChange={(e) => setTo(e.target.value)}
                    />
                  </div>

                  <button onClick={handleRunTrip} className='p-1 pl-[20px] pr-[20px] rounded-md text-white' style={{ background: "#1996F0" }}>Run trip</button>
                </div>
            
            </div>

          </div>
     
      <MapContainer
        center={[41.2995, 69.2401]} // Default Toshkent
        zoom={6}
        style={{ height: "500px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map((marker, index) => (
          <Marker key={index} position={marker.position}>
            <Popup>{marker.name}</Popup>
          </Marker>
        ))}
      </MapContainer>


        </div>

      </main>


    </div>
  )
}

export default TripPannel