import React, { useContext, useState } from "react";
import axios from "axios";
import './dashboardregister.css'
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../DashboarNavbar/DashboardNavbar";
import SubscriptionPlan from "./SubscriptionPlan";
import BillingHistory from "./BillingHistory";
import { UserContext } from "../../../App";
const RegistrationForm = () => {

    const [activeRegister,setActiveRegister] = useState('companydetails')
    const [formData, setFormData] = useState({
        companyName: "",
        phoneNumber: "",
        email: "",
        timezone: "",
        companyAddress: "",
        usdot: "",
        terminalAddress: ""
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post("/api/registerCompany", formData);
            alert("Registration successful!");
            console.log(response.data);
        } catch (error) {
            console.error("Error registering company:", error);
            alert("Failed to register. Please try again.");
        }
    };


    const formChanger = (click) =>{
       setActiveRegister(click)
        
    }

    const user = useContext(UserContext)

    return (
        <div className="dashboard pl-2 pr-2" style={{background:user.night ? '#fff' : "#1E2D4C",color:user.night ? '' : "#fff",transition:'all 0.3s ease'}}>

            <Sidebar />

            <main className="main-content" >
                <Navbar />

                <div className=" w-full p-4 bg-white rounded-lg" style={{background:user.night ? '#fff' : "#1E2D4C",color:user.night ? '' : "#fff",transition:'all 0.3s ease'}}>

                    <h2 className="text-2xl font-bold" style={{ color: "#1996F0" }}>Company</h2>
                    <div className="flex items-center gap-3 border-b-2 pb-2">
                        <p className="font-semibold cursor-pointer" onClick={ () =>formChanger('companydetails')}>Company details</p>
                        <p className="font-semibold cursor-pointer" onClick={() =>formChanger('subscriptionplan')}>Subscription plan</p>
                        <p className="font-semibold cursor-pointer" onClick={ () =>formChanger('billinghistory')}>Billing history</p>
                        <p className="font-semibold cursor-pointer" onClick={ () =>formChanger('apitoken')}>API & Token</p>
                    </div>                

                {
                    activeRegister === 'companydetails'
                    ?
                    <form onSubmit={handleSubmit} >
                    <div className="flex items-start justify-between gap-3">


                        <div className="w-1/3">
                            <label className="w-3/6 font-semibold">
                                Company name: <br />
                                <input type="text" placeholder="Abrams Trucking LLC" name="companyName" value={formData.companyName} onChange={handleChange} className=" w-full border mb-2 border-gray-300 p-1 rounded-lg mt-2" required/>
                            </label>

                            <label className="w-3/6 font-semibold">
                                Company address: <br />
                                <input type="text" placeholder="221 Highland Ave Trenton NJ 08618" name="companyAddress" value={formData.companyAddress} onChange={handleChange} required className=" w-full border mb-2 border-gray-300 p-1 rounded-lg mt-2" />
                            </label>

                            <label className="w-3/6 font-semibold">
                                Company timezone: <br />
                                <select name="timezone"  value={formData.timezone} onChange={handleChange} required className=" w-full border border-gray-300 p-1 mb-2 rounded-lg mt-2 text-gray-400">
                                    <option value="" selected>Select timezone</option>
                                    <option value="GMT">GMT</option>
                                    <option value="UTC">UTC</option>
                                    <option value="CDT">CDT</option>
                                    {/* Qo'shimcha vaqt zonalarini qo'shing */}
                                </select>
                            </label>

                        </div>


                        <div className="w-1/3">
                            <label className="w-3/6 font-semibold">
                                Email: <br />
                                <input type="email" placeholder="exampleuzer@gmail.com" name="email" value={formData.email} onChange={handleChange} required className=" w-full border mt-2 mb-2 border-gray-300 p-1 rounded-lg" />
                            </label>

                            <label className="w-3/6 font-semibold">
                                Terminal address: <br />
                                <input type="text" placeholder="221 Highland Ave Trenton NJ 08618" name="terminalAddress" value={formData.terminalAddress} onChange={handleChange} required className=" w-full mt-2 mb-2 border border-gray-300 p-1 rounded-lg" />
                            </label>

                        </div>


                        <div className="w-1/3">
                            <label className="w-3/6 font-semibold">
                                Phone number: <br />
                                <input type="text" placeholder="+1 331 837 00 00" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className=" w-full border mb-2 border-gray-300 p-1 mt-2 rounded-lg" required />
                            </label>
                            <label className="w-3/6 font-semibold">
                                USDOT: <br />
                                <input type="text" placeholder="1730282" name="usdot" value={formData.usdot} onChange={handleChange} required className=" w-full border border-gray-300 p-1 rounded-lg mt-2 mb-2" />
                            </label>
                            <div className="w-3/6 flex items-center justify-start">
                                <button type="submit" className="p-3 bg-blue-500 text-white rounded-xl">Register</button>
                            </div>
                        </div>






                    </div>
                </form>
                :
                activeRegister === 'subscriptionplan'
                ?
                <SubscriptionPlan/>
                :
                activeRegister === 'billinghistory'
                ?
                <BillingHistory/>
                :
                <></>
                }

                

                </div>

                

            </main>

        </div>
    );
};

export default RegistrationForm;
